import { createSlice } from "@reduxjs/toolkit";
import { method } from "lodash";

const overtimeSlice = createSlice({
  name: "overtimeData",

  initialState: {
    overtimePolicyId: null,
    overtimePolicyName: null,
    methodsForOvertimeId: null,
    methodsForOvertime: [],
  },

  reducers: {
    setOvertimePolicyId: (state, action) => {
      state.overtimePolicyId = action.payload;
      console.log("overtimePolicyId", state.overtimePolicyId);
    },

    setMethodsForOvertimeId: (state, action) => {
      state.methodsForOvertimeId = action.payload;
      console.log("setMethodsForOvertimeId", state.methodsForOvertimeId);
    },

    setMethodsForOvertime: (state, action) => {
      state.methodsForOvertime = action.payload;
      console.log("setMethodsForOvertime", state.methodsForOvertime);
    },

    setOvertimePolicyName: (state, action) => {
      state.overtimePolicyName = action.payload;
      console.log("overtimePolicyName", state.overtimePolicyName);
    },
  },
});

export const {
  setOvertimePolicyId,
  setMethodsForOvertimeId,
  setMethodsForOvertime,
  setOvertimePolicyName,
} = overtimeSlice.actions;

export default overtimeSlice.reducer;
