import React from 'react';
import { Field, Formik } from 'formik';
import { Button, Input, Label } from '@windmill/react-ui';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  GithubIcon,
  TwitterIcon,
  ImageLoginOffice,
} from '../../../../assets/asset';

import useInitialData from '../../../../hooks/useInitialData';
import { INITIAL_DATA } from './constants';
import { ErrorMessage } from '../../../../components/UI Components';
import { Dropdown as DropdownOp } from '../../../../components/UI Components';
import { logInSchema } from '../schema';

const SignIn = ({ handleFormSubmit, initialData }) => {
  const { t } = useTranslation();

  const { isDataEmpty, initialValues } = useInitialData({
    initialData,
    INITIAL_DATA,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      enableReinitialize={isDataEmpty}
      validationSchema={logInSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
          <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
            <div className="flex flex-col overflow-y-auto md:flex-row">
              <div className="h-32 md:h-auto md:w-1/2">
                <img
                  aria-hidden="true"
                  className="object-cover w-full h-full dark:hidden"
                  src={ImageLoginOffice}
                  alt="Office"
                />
                <img
                  aria-hidden="true"
                  className="hidden object-cover w-full h-full dark:block"
                  src={ImageLoginOffice}
                  alt="Office"
                />
              </div>
              <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                <form
                  className="w-full"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                    {t('LOGIN')}
                  </h1>
                  <Label>
                    <span>{t('EMAIL')}</span>
                    <Field
                      as={Input}
                      type="email"
                      className="mt-1 text-transform: lowercase"
                      placeholder={t('YOUR_EMAIL')}
                      name="email"
                    />
                  </Label>
                  <ErrorMessage name="email" />

                  <Label>
                    <span>{t('PASSWORD')}</span>
                    <Field
                      as={Input}
                      type="password"
                      className="mt-4 "
                      placeholder={t('**************')}
                      name="password"
                    />
                  </Label>
                  <ErrorMessage name="password" />

                  <Button
                    className="mt-4"
                    block
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t('LOGIN')}
                  </Button>

                  <hr className="my-8" />

                  <Button block layout="outline">
                    <GithubIcon className="w-4 h-4 mr-2" aria-hidden="true" />
                    Google
                  </Button>
                  <Button className="mt-4" block layout="outline">
                    <TwitterIcon className="w-4 h-4 mr-2" aria-hidden="true" />
                    Twitter
                  </Button>

                  <p className="mt-4">
                    <Link
                      className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                      to="/forgot-password"
                    >
                      {t('FORGOT_YOUR_PASSWORD')}
                    </Link>
                  </p>
                  <p className="mt-1">
                    <Link
                      className="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline"
                      to="/register"
                    >
                      {t('CREATE_ACCOUNT')}
                    </Link>
                  </p>
                </form>
              </main>
              <DropdownOp />
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default SignIn;
