import { AUTH_TOKEN_KEY } from '../constants/common';
import appLogger from './appLogger';

class Config {
  constructor() {
    this.config = this.initConfig();
    this.authStoreName = AUTH_TOKEN_KEY;
  }

  environmentName() {
    return process.env.REACT_APP_SERVER || 'local';
  }

  initConfig() {
    const environment = this.environmentName();
    const envData = require(`./${environment}.json`);
    appLogger.log('config', envData);
    return envData;
  }

  getConfig() {
    return {
      ...this.config,
      authStorageKeyName: this.authStoreName,
    };
  }

  isDev() {
    const environment = this.environmentName();
    return ['local', 'staging'].includes(environment);
  }

  isProd() {
    const environment = this.environmentName();
    return ['production'].includes(environment);
  }

  getUserAuthDetails() {
    const localData = window.localStorage.getItem(this.authStoreName);
    if (localData !== null) {
      return localData;
    }
    return null;
  }

  getAuthStoreName() {
    return this.authStoreName;
  }
}

export default new Config();
