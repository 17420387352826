import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { Input, Label, Button } from "@windmill/react-ui";
import SearchSelect from "react-select";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { searchEmployees } from "../../../employee/api/employee";
import {
  BUSINESS_UNIT_INITIAL_DATA,
  COST_CENTER_INITIAL_DATA,
  DEPARTMENT_INITIAL_DATA,
  DIVISION_INITIAL_DATA,
} from "./Constant";
import { DeptDivBUCCSchema } from "../schema";
import { isEmpty, isFunction } from "lodash";
import { Link } from "react-router-dom";

const DeptDivBUCCForm = ({
  handleFormSubmit,
  initialValue,
  formId,
  entityType,
  buttons,
}) => {
  const [employeeHeads, setEmployeeHeads] = useState([]);
  const { companyId } = useSelector((state) => state.companyData);

  const { data: dataEmployee, isLoading } = useQuery({
    queryKey: ["employeeList", companyId],
    queryFn: () => searchEmployees({ companyId }),
    keepPreviousData: true,
  });

  const options = useMemo(() => {
    if (dataEmployee) {
      return dataEmployee?.rows?.map((employee) => ({
        value: employee.id,
        label: employee.employee.fullName.trim(),
      }));
    }
    return [];
  }, [dataEmployee]);

  const entityLabels = {
    division: "Division",
    department: "Department",
    businessUnit: "Business Unit",
    costCenter: "Cost Center",
  };
  const initialData = {
    division: DIVISION_INITIAL_DATA,
    department: DEPARTMENT_INITIAL_DATA,
    businessUnit: BUSINESS_UNIT_INITIAL_DATA,
    costCenter: COST_CENTER_INITIAL_DATA,
  };

  const entityLabel = entityLabels[entityType] || "Entity";
  const isViewMode = !handleFormSubmit;
  const initialValues = {
    ...initialData[entityType],
    ...initialValue,
  };

  useEffect(() => {
    if (initialValue && !isEmpty(initialValue[`${entityType}Head`])) {
      let employee = [];
      initialValue[`${entityType}Head`].forEach((element) => {
        employee.push(options.find((employee) => employee.value === element));
      });
      setEmployeeHeads(employee);
    }
  }, [entityType, initialValue, options]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DeptDivBUCCSchema}
      onSubmit={handleFormSubmit || (() => {})} // no-op function for view mode
      enableReinitialize={true}
    >
      {({ handleSubmit, setFieldValue, isSubmitting, values }) => (
        <form
          id={formId}
          autoComplete="off"
          className={`space-y-4 ${
            isFunction(buttons) ? "bg-white shadow-xl pb-6" : ""
          } `}
          onSubmit={isViewMode ? (e) => e.preventDefault() : handleSubmit}
        >
          <div className="mb-10 ml-5">
            <div className="grid md:grid-cols-2 grid-cols-1  gap-4 ml-4 mb-6">
              <span className="text-base flex flex-col">
                {entityLabel} Name*
              </span>
              <Label>
                <Field
                  as={Input}
                  type="text"
                  className="mb-3 border border-black"
                  placeholder={`Enter ${entityLabel} Name`}
                  name={`name`}
                  disabled={isViewMode}
                />
                <ErrorMessage
                  name={`name`}
                  component="div"
                  className="text-red-600"
                />
              </Label>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1  gap-4 ml-4 mb-6">
              <span className="text-base flex flex-col">
                {entityLabel} Code*
              </span>
              <Label>
                <Field
                  as={Input}
                  type="text"
                  className="mb-3 border border-black"
                  placeholder={`Enter ${entityLabel} Code`}
                  name={"code"}
                  disabled={isViewMode}
                />
                <ErrorMessage
                  name={"code"}
                  component="div"
                  className="text-red-600"
                />
              </Label>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1  gap-4 ml-4 mb-6">
              <span className="text-base flex flex-col">Description</span>
              <Label>
                <Field
                  as={Input}
                  type="text"
                  className="mb-3 border border-black"
                  placeholder={`Enter ${entityLabel} Description`}
                  name="description"
                  disabled={isViewMode}
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-600"
                />
              </Label>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1  items-center gap-4 ml-4 mb-6">
              <span className="text-base flex flex-col">
                {entityLabel} Head(s)
              </span>
              <Label>
                <SearchSelect
                  className="mt-1 mb-6 border"
                  name={`${entityType}Head`}
                  placeholder={`Select ${entityLabel} Heads`}
                  isMulti
                  value={employeeHeads}
                  onChange={(selectedOptions) => {
                    if (!isViewMode) {
                      setEmployeeHeads(selectedOptions);
                      const selectedIds = selectedOptions
                        ? selectedOptions.map((option) => option.value)
                        : [];
                      setFieldValue(`${entityType}Head`, selectedIds);
                    }
                  }}
                  options={options}
                  isDisabled={isViewMode}
                />
              </Label>
            </div>
          </div>
          {isFunction(buttons) && buttons(isSubmitting)}
        </form>
      )}
    </Formik>
  );
};

export default DeptDivBUCCForm;
