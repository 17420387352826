import api, { commonThenResult } from './mtrack';

export const END_POINTS = {
  ADD_VENDOR: () => '/api/vendor/vendor-entity/add',
  ADD_VENDOR_ADDRESS: () => '/api/vendor/vendor-address/add',
  ADD_VENDOR_DOCUMENT: () => '/api/vendor/vendor-document/add',
  ADD_VENDOR_FINANCE: () => '/api/vendor/vendor-finance/add',
  ADD_VENDOR_EMPANELMENT: () => '/api/vendor/vendor-empanelment/add',
  ADD_VENDOR_EMPANELMENT_LOCATION: () =>
    '/api/vendor/vendor-empanelment-location/add',
  ADD_CONTACT: () => 'api/vendor/contact/add',

  LIST_VENDOR: () => '/api/vendor/vendor-entity/list',
  GET_VENDOR: () => '/api/vendor/vendor-entity',
  GET_VENDOR_REQUIRED_DOCUMENTS: () => '/api/vendor/vendor-document/required',

  EDIT_VENDOR: () => '/api/vendor/vendor-entity/edit',
  EDIT_VENDOR_ADDRESS: () => '/api/vendor/vendor-address/edit',
  EDIT_VENDOR_DOCUMENT: () => '/api/vendor/vendor-document/edit',
  EDIT_VENDOR_FINANCE: () => '/api/vendor/vendor-finance/edit',
  EDIT_VENDOR_EMPANELMENT: () => '/api/vendor/vendor-empanelment/edit',
  EDIT_VENDOR_EMPANELMENT_LOCATION: () => '/api/vendor/vendor-empanelment/edit',
  EDIT_CONTACT: () => 'api/vendor/contact/edit',
};

export const addContact = (inputData) =>
  api.post(END_POINTS.ADD_CONTACT(), inputData);

export const addVendor = (inputData) =>
  api.post(END_POINTS.ADD_VENDOR(), inputData);

export const addVendorEmpanelment = (inputData) =>
  api.post(END_POINTS.ADD_VENDOR_EMPANELMENT(), inputData);

export const addVendorAddress = (inputData) =>
  api.post(END_POINTS.ADD_VENDOR_ADDRESS(), inputData);

export const addVendorDocument = (inputData) =>
  api.post(END_POINTS.ADD_VENDOR_DOCUMENT(), inputData);

export const addVendorFinance = (inputData) =>
  api.post(END_POINTS.ADD_VENDOR_FINANCE(), inputData);

export const addVendorEmpanelmentLocation = (inputData) =>
  api.post(END_POINTS.ADD_VENDOR_EMPANELMENT_LOCATION(), inputData);

export const listVendors = async (inputData) => {
  const response = await api.get(END_POINTS.LIST_VENDOR(), {
    params: {
      companyId: inputData.companyId,
      limit: inputData.resultsPerPage,
      offset: inputData.page,
    },
  });

  return commonThenResult(response);
};
export const getVendor = async (inputData) => {
  const response = await api.get(END_POINTS.GET_VENDOR(), {
    params: {
      vendorId: inputData,
    },
  });
  return commonThenResult(response);
};

export const getVendorRequiredDocuments = async (inputData) => {
  const response = await api.get(END_POINTS.GET_VENDOR_REQUIRED_DOCUMENTS(), {
    params: {
      companyId: inputData,
    },
  });

  return commonThenResult(response);
};

// EDIT API

export const editVendor = (param, inputData) =>
  api.patch(END_POINTS.EDIT_VENDOR(), {
    params: {
      cinNum: param,
    },
    data: inputData,
  });

export const editVendorEmpanelment = (inputData) =>
  api.patch(END_POINTS.EDIT_VENDOR_EMPANELMENT(), {
    params: {
      empanelmentId: inputData.empanelmentId,
    },
    data: inputData,
  });

export const editVendorAddress = (param, inputData) => {
  return api.patch(END_POINTS.EDIT_VENDOR_ADDRESS(), {
    params: {
      locationId: param,
    },
    data: inputData,
  });
};

export const editVendorDocument = (param, inputData) =>
  api.patch(END_POINTS.EDIT_VENDOR_DOCUMENT(), {
    params: {
      documentId: param,
    },
    data: inputData,
  });

export const editVendorFinance = (param, inputData) =>
  api.patch(END_POINTS.EDIT_VENDOR_FINANCE(), {
    params: {
      bankId: param,
    },
    data: inputData,
  });

export const editVendorEmpanelmentLocation = (param, inputData) =>
  api.patch(END_POINTS.EDIT_VENDOR_EMPANELMENT_LOCATION(), {
    params: {
      empanelmentLoactionId: param,
    },
    data: inputData,
  });

export const editContact = (param, inputData) => {
  return api.patch(END_POINTS.EDIT_CONTACT(), {
    params: {
      contactId: param,
    },
    data: inputData,
  });
};
