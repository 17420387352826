import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  employeeId: null,
  EmployeeEmpanelmentId: null,
  panNum: '',
  aadhar: '',
};

const employeeFormData = createSlice({
  name: 'employeeFormData',
  initialState,
  reducers: {
    captureEmployeeFormData(state, action) {
      //   console.log(action);

      state.employeeId = action.payload.employeeId;
      state.EmployeeEmpanelmentId = action.payload.EmployeeEmpanelmentId;
      state.aadhar = action.payload.aadhar;
      state.panNum = action.payload.panNum;
    },
  },
});

export const { captureEmployeeFormData } = employeeFormData.actions;

export default employeeFormData.reducer;
