import { lazy } from "react";
import { v4 as uuidv4 } from "uuid";
import overtimeApprovals from "../../../modules/overtime/pages/overtimeApprovals";

const Dashboard = lazy(() => import("../../../modules/dashboard"));
const CompanyMaster = lazy(() => import("../../../modules/companyMaster"));

// const Dashboard = lazy(() => import('../../pages/Dashboard'));
const VendorReg = lazy(() =>
  import("../../../modules/vendor/component/VendorReg")
);
const Vendor = lazy(() => import("../../../modules/vendor/pages/Vendor"));
const ViewVendor = lazy(() =>
  import("../../../modules/vendor/pages/ViewVendor")
);
const AddVendor = lazy(() =>
  import("../../../modules/vendor/pages/Add/AddVendor")
);
const AddressForm = lazy(() =>
  import("../../../modules/vendor/pages/Add/AddressForm")
);
const EmployeeReg = lazy(() =>
  import("../../../modules/employee/component/EmployeeReg")
);
const ListEmployees = lazy(() =>
  import("../../../modules/employee/pages/ListEmployees")
);
const ViewEmployee = lazy(() =>
  import("../../../modules/employee/pages/ViewEmployee")
);

const UploadEmployeeList = lazy(() =>
  import("../../../modules/employee/component/UploadEmployeeList")
);

//Company Policy

const CapturePolicy = lazy(() =>
  import("../../../modules/companyPolicy/pages/capturePolicy/capturePolicy")
);

const AddCapturePolicy = lazy(() =>
  import("../../../modules/companyPolicy/forms/capturePolicy/addCapturePolicy")
);

const CapturePolicyDetails = lazy(() =>
  import(
    "../../../modules/companyPolicy/pages/capturePolicy/capturePolicyDetails"
  )
);

//penalizationPolicy

const PenalizationPolicy = lazy(() =>
  import(
    "../../../modules/companyPolicy/pages/penalizationPolicy/penalizationPolicy"
  )
);

const AddPenalizationPolicy = lazy(() =>
  import(
    "../../../modules/companyPolicy/forms/penalizationPolicy/addPenalizationPolicy"
  )
);

const PenalizationPolicyDeails = lazy(() =>
  import(
    "../../../modules/companyPolicy/pages/penalizationPolicy/penalizationPolicyDetails"
  )
);

// sepreation

const Resignation = lazy(() =>
  import("../../../modules/separation/pages/Resignation")
);
const Termination = lazy(() =>
  import("../../../modules/separation/pages/Termination")
);
const Reports = lazy(() => import("../../../modules/separation/pages/Reports"));

const AddFinancialDetails = lazy(() =>
  import("../../../modules/vendor/pages/Add/FinancialDetails")
);
const AddDocumentDetails = lazy(() =>
  import("../../../modules/vendor/pages/Add/DocumentDetails")
);
const DesignationList = lazy(() =>
  import("../../../modules/designation/pages/DesignationList")
);

const AddBulkDesignation = lazy(() =>
  import("../../../modules/designation/pages/AddBulkDesignation")
);

const DepartmentList = lazy(() =>
  import("../../../modules/department/pages/DepartmentList")
);

const AddBulkDepartment = lazy(() =>
  import("../../../modules/department/pages/AddBulkDepartment")
);

const AssignStructure = lazy(() =>
  import("../../../modules/payroll/pages/AssignStructure")
);

const RunPayroll = lazy(() =>
  import("../../../modules/payroll/pages/RunPayroll")
);

const ChallanGenerationPage = lazy(() =>
  import("../../../modules/payroll/pages/ChallanGeneration")
);

const TaxationPage = lazy(() =>
  import("../../../modules/payroll/pages/Taxation")
);

const ManageStructure = lazy(() =>
  import("../../../modules/payroll/pages/ManageStructure")
);

const ViewStructure = lazy(() =>
  import(
    "../../../modules/payroll/components/ManageStructure/view edit structure/ViewStructure"
  )
);

const OutstandingOnEmployee = lazy(() =>
  import("../../../modules/payroll/pages/OutstandingOnEmployee")
);

const IncentiveAndOT = lazy(() =>
  import("../../../modules/payroll/pages/IncentiveAndOT")
);

const AttendenceHome = lazy(() =>
  import("../../../modules/attendence/pages/AttendenceHome")
);

//Shift

const ShiftManagement = lazy(() =>
  import("../../../modules/shiftAndDayOffs/pages/shiftManagement")
);
const DayOffManagement = lazy(() =>
  import("../../../modules/shiftAndDayOffs/pages/dayOffsManagement")
);
const AddShiftView = lazy(() =>
  import("../../../modules/shiftAndDayOffs/pages/shiftManagement/AddShiftView")
);

//New Shift

const Shift = lazy(() => import("../../../modules/shifts/pages/shift"));

const ViewShift = lazy(() => import("../../../modules/shifts/pages/ViewShift"));

const EditShiftForm = lazy(() =>
  import("../../../modules/shifts/pages/EditShift/EditShiftForm")
);

const EditShift = lazy(() =>
  import("../../../modules/shifts/pages/EditShift/index")
);

const AddNewShiftView = lazy(() =>
  import("../../../modules/shifts/pages/addShifts")
);

const AddAutoShift = lazy(() =>
  import("../../../modules/shifts/pages/addAutoShift")
);

// Shift Roster

const ShiftRoster = lazy(() =>
  import("../../../modules/shiftRoster/pages/rosterMaster")
);

const ViewRosterDetails = lazy(() =>
  import("../../../modules/shiftRoster/pages/ViewRosterDetails")
);

const AddRoster = lazy(() =>
  import("../../../modules/shiftRoster/pages/addRoster")
);

const RosterView = lazy(() =>
  import("../../../modules/rosterView/pages/rosterView")
);

const UpdateWeekOff = lazy(() =>
  import("../../../modules/rosterView/pages/UpdateWeekOff")
);

const UpdateShift = lazy(() =>
  import("../../../modules/rosterView/pages/UpdateShift/UpdateShift")
);

//Leave

const LeaveRequest = lazy(() =>
  import("../../../modules/Leave/pages/LeaveRequest")
);
const CompensatoryRequest = lazy(() =>
  import("../../../modules/Leave/pages/CompensatoryOff")
);
const BalanceTable = lazy(() =>
  import("../../../modules/Leave/pages/LeaveBalance/Balance")
);
const LeaveSettings = lazy(() =>
  import("../../../modules/Leave/pages/LeaveSettings")
);
const AddLeaveType = lazy(() =>
  import("../../../modules/Leave/forms/AddLeaveType")
);

// Location

const Location = lazy(() => import("../../../modules/location/pages/Location"));
const AddLocation = lazy(() =>
  import("../../../modules/location/pages/AddLocation")
);
const ViewLocation = lazy(() =>
  import("../../../modules/location/pages/ViewLocation")
);

const AddBulkLocation = lazy(() =>
  import("../../../modules/location/pages/AddBulkLocation")
);

// Holiday

const Holiday = lazy(() =>
  import("../../../modules/holiday/pages/ListHolyday")
);

const AddHoliday = lazy(() =>
  import("../../../modules/holiday/pages/AddHoliday")
);

const AddBulkHoliday = lazy(() =>
  import("../../../modules/holiday/pages/AddBulkHoliday")
);

// Role & Permissions

const RolePermissionsHome = lazy(() =>
  import("../../../modules/role&permissions/pages/RolePermissionsHome")
);

const EditAccess = lazy(() =>
  import("../../../modules/role&permissions/pages/EditAccess")
);

// job

const JobOpenings = lazy(() =>
  import("../../../modules/Job/pages/JobOpenings")
);

const JoiningRequests = lazy(() =>
  import("../../../modules/Job/pages/JoiningRequests")
);

const AddJobOpening = lazy(() =>
  import("../../../modules/Job/pages/AddJobOpening")
);

const ViewOpenings = lazy(() =>
  import("../../../modules/Job/pages/ViewOpenings")
);

const ViewCandidate = lazy(() =>
  import("../../../modules/Job/pages/ViewCandidate")
);

//policy

const CompanyPolicy = lazy(() => import("../../../modules/policy"));
// const Page404 = lazy(() => import('../../pages/404'))
// const Blank = lazy(() => import('../../pages/Blank'))

//Leave Master
const LeaveMaster = lazy(() =>
  import("../../../modules/leaves&holidays/pages/LeaveMaster/LeaveMaster")
);
const LeaveBalance = lazy(() =>
  import("../../../modules/leaves&holidays/pages/Leavebalance/LeaveBalance")
);
const LeavePolicyAssignment = lazy(() =>
  import(
    "../../../modules/leaves&holidays/pages/LeaveMaster/LeavePolicyAssignment"
  )
);

const ViewLeave = lazy(() =>
  import("../../../modules/leaves&holidays/pages/LeaveMaster/ViewLeaveTypes")
);
const AddLeaveTypes = lazy(() =>
  import("../../../modules/leaves&holidays/pages/LeaveMaster/AddLeaveTypes")
);
const AddLeavePolicy = lazy(() =>
  import("../../../modules/leaves&holidays/pages/LeaveMaster/AddLeavePolicy")
);
const ViewLeavePolicy = lazy(() =>
  import("../../../modules/leaves&holidays/pages/LeaveMaster/ViewLeavePolicy")
);
const ConfigureLeavePolicy = lazy(() =>
  import(
    "../../../modules/leaves&holidays/pages/LeaveMaster/ConfigureLeavePolicy"
  )
);
const LeavesRequest = lazy(() =>
  import("../../../modules/leaves&holidays/pages/LeaveRequest/LeaveRequest.js")
);

//Organisation Structure
const OrganisationSetup = lazy(() =>
  import(
    "../../../modules/Organisation/pages/OrganisationSetup/OrganisationSetup.js"
  )
);
const Band = lazy(() =>
  import("../../../modules/Organisation/pages/Band/Band.js")
);

const BusinessUnit = lazy(() =>
  import("../../../modules/Organisation/pages/BusinessUnit/BusinessUnit.js")
);
const CostCenter = lazy(() =>
  import("../../../modules/Organisation/pages/CostCenter/CostCenter.js")
);
const Department = lazy(() =>
  import("../../../modules/Organisation/pages/Department/Department.js")
);
const Division = lazy(() =>
  import("../../../modules/Organisation/pages/Division/Division.js")
);
const LegalEntity = lazy(() =>
  import("../../../modules/Organisation/pages/LegalEntity/LegalEntity.js")
);
const ViewLegalEntity = lazy(() =>
  import("../../../modules/Organisation/pages/LegalEntity/ViewLegalEntity.js")
);
const AddLegalEntity = lazy(() =>
  import("../../../modules/Organisation/pages/LegalEntity/AddLegalEntity.js")
);
const EditLegalEntity = lazy(() =>
  import("../../../modules/Organisation/pages/LegalEntity/EditLegalEntity.js")
);
const OrganisationLocation = lazy(() =>
  import("../../../modules/Organisation/pages/Location/Location.js")
);
const ViewOrganisationLocation = lazy(() =>
  import("../../../modules/Organisation/pages/Location/ViewLocation.js")
);
const AddOrganisationLocation = lazy(() =>
  import("../../../modules/Organisation/pages/Location/AddLocation.js")
);
const EditOrganisationLocation = lazy(() =>
  import("../../../modules/Organisation/pages/Location/EditLocation.js")
);
const PayGrades = lazy(() =>
  import("../../../modules/Organisation/pages/PayGrades/PayGrades.js")
);
const SubDepartment = lazy(() =>
  import("../../../modules/Organisation/pages/SubDepartment/SubDepartment.js")
);
//OverTime

const Overtime = lazy(() => import("../../../modules/overtime/pages/overtime"));

const OvertimeApprovals = lazy(() => import("../../../modules/overtime/pages/overtimeApprovals"));

const OvertimeDetails = lazy(() => import("../../../modules/overtime/pages/overtimeDetails"));

const OvertPolicyViews = lazy(() => import("../../../modules/overtime/pages/overtimePolicyView"));

const OvertimeDetailsViews = lazy(() => import("../../../modules/overtime/pages/overtimeDayDetailsView"));

const OvertimePolicyAllocation = lazy(() => import("../../../modules/overtime/pages/OvertimePolicyAllocation"));

const routes = [
  {
    id: uuidv4(),
    path: "/dashboard", // the url
    component: Dashboard, // view rendered
  },

  {
    id: uuidv4(),
    path: "/company-master",
    component: CompanyMaster,
  },
  {
    id: uuidv4(),
    path: "/vendor",
    component: Vendor,
  },
  {
    id: uuidv4(),

    path: "/add-vendor",
    component: VendorReg,
  },
  {
    id: uuidv4(),
    path: "/view-vendor/:vendorId",
    component: ViewVendor,
  },
  { id: uuidv4(), path: "/list-employees", component: ListEmployees },
  { id: uuidv4(), path: "/view-employee/:employeeId", component: ViewEmployee },
  { id: uuidv4(), path: "/add-employee", component: EmployeeReg },
  { id: uuidv4(), path: "/add-employee/bulk", component: UploadEmployeeList },
  { id: uuidv4(), path: "/add-address/:vendorId", component: AddressForm },
  {
    id: uuidv4(),
    path: "/add-financial-detail/:vendorId",
    component: AddFinancialDetails,
  },
  {
    id: uuidv4(),
    path: "/add-documents/:vendorId",
    component: AddDocumentDetails,
  },
  { id: uuidv4(), path: "/addvendorhard", component: AddVendor },
  { id: uuidv4(), path: "/designation-master", component: DesignationList },
  {
    id: uuidv4(),
    path: "/add-designation/bulk",
    component: AddBulkDesignation,
  },
  { id: uuidv4(), path: "/department-master", component: DepartmentList },
  { id: uuidv4(), path: "/add-department/bulk", component: AddBulkDepartment },
  { id: uuidv4(), path: "/leave-requests", component: LeaveRequest },
  {
    id: uuidv4(),
    path: "/compensatory-request",
    component: CompensatoryRequest,
  },
  { id: uuidv4(), path: "/leave-balance", component: BalanceTable },
  { id: uuidv4(), path: "/leave-settings", component: LeaveSettings },
  { id: uuidv4(), path: "/add-leaveType", component: AddLeaveType },
  { id: uuidv4(), path: "/payroll-assign", component: AssignStructure },
  { id: uuidv4(), path: "/payroll-process/", component: RunPayroll },
  { id: uuidv4(), path: "/payroll-setting/", component: ManageStructure },
  {
    id: uuidv4(),
    path: "/payroll-setting/view-structure/:companyStructureId",
    component: ViewStructure,
  },
  { id: uuidv4(), path: "/payroll-challan", component: ChallanGenerationPage },
  { id: uuidv4(), path: "/taxation", component: TaxationPage },
  {
    id: uuidv4(),
    path: "/outstanding-employee",
    component: OutstandingOnEmployee,
  },
  { id: uuidv4(), path: "/incentive-ot", component: IncentiveAndOT },

  { id: uuidv4(), path: "/attendance", component: AttendenceHome },
  { id: uuidv4(), path: "/locations", component: Location },
  { id: uuidv4(), path: "/add-location", component: AddLocation },
  { id: uuidv4(), path: "/add-location/bulk", component: AddBulkLocation },
  { id: uuidv4(), path: "/view-location/:locationId", component: ViewLocation },
  { id: uuidv4(), path: "/holiday", component: Holiday },
  { id: uuidv4(), path: "/holiday/add-holiday", component: AddHoliday },
  { id: uuidv4(), path: "/add-holiday/bulk", component: AddBulkHoliday },
  { id: uuidv4(), path: "/policy", component: CompanyPolicy },
  { id: uuidv4(), path: "/job-openings", component: JobOpenings },
  { id: uuidv4(), path: "/leave-master", component: LeaveMaster },
  { id: uuidv4(), path: "/leaves-balances", component: LeaveBalance },
  { id: uuidv4(), path: "/leaves-requests", component: LeavesRequest },
  { id: uuidv4(), path: "/view-leave/:leaveTypeId", component: ViewLeave },
  { id: uuidv4(), path: "/add-leave-type", component: AddLeaveTypes },
  { id: uuidv4(), path: "/add-leave-policy", component: AddLeavePolicy },
  {
    id: uuidv4(),
    path: "/leave-policy-assignment",
    component: LeavePolicyAssignment,
  },
  {
    id: uuidv4(),
    path: "/view-leave-policy/:leavePolicyId",
    component: ViewLeavePolicy,
  },
  {
    id: uuidv4(),
    path: "/configure-leave-policy-leave-type/:leaveTypeId",
    component: ConfigureLeavePolicy,
  },

  {
    id: uuidv4(),
    path: "/job-view-opening/:openingId",
    component: ViewOpenings,
  },
  {
    id: uuidv4(),
    path: "/job-view-candidate/:candidateId",
    component: ViewCandidate,
  },
  { id: uuidv4(), path: "/job-request", component: JoiningRequests },
  { id: uuidv4(), path: "/job-opening-add", component: AddJobOpening },

  {
    id: uuidv4(),
    path: "/resignation-requests",
    component: Resignation,
  },
  {
    id: uuidv4(),
    path: "/termination",
    component: Termination,
  },
  {
    id: uuidv4(),
    path: "/reports",
    component: Reports,
  },
  {
    id: uuidv4(),
    path: "/attendance/shift",
    component: ShiftManagement,
  },

  // new shift

  {
    id: uuidv4(),
    path: "/attendance/shifts",
    component: Shift,
  },
  { id: uuidv4(), path: "/view-shifts/:shiftId", component: ViewShift },
  // { id: uuidv4(), path: "/edit-shifts/:shiftId", component: EditShiftForm },
  { id: uuidv4(), path: "/edit-shifts/:shiftId", component: EditShift },
  {
    id: uuidv4(),
    path: "/add-newShift",
    component: AddNewShiftView,
  },

  {
    id: uuidv4(),
    path: "/add-autoShift",
    component: AddAutoShift,
  },

  // shift roster
  {
    id: uuidv4(),
    path: "/shift-roster",
    component: ShiftRoster,
  },

  {
    id: uuidv4(),
    path: "/view-rosterDetails/:rosterId",
    component: ViewRosterDetails,
  },

  // add-roster
  {
    id: uuidv4(),
    path: "/add-roster",
    component: AddRoster,
  },

  // Roster View
  {
    id: uuidv4(),
    path: "/roster-view",
    component: RosterView,
  },

  {
    id: uuidv4(),
    path: "/update-week-off",
    component: UpdateWeekOff,
  },

  {
    id: uuidv4(),
    path: "/update-shift",
    component: UpdateShift,
  },

  {
    id: uuidv4(),
    path: "/attendance/day-off",
    component: DayOffManagement,
  },
  {
    id: uuidv4(),
    path: "/add-shift",
    component: AddShiftView,
  },

  { id: uuidv4(), path: "/rolePermission", component: RolePermissionsHome },
  { id: uuidv4(), path: "/edit-access/:userRoleId", component: EditAccess },
  //Company Policy
  {
    id: uuidv4(),
    path: "/capture-policy",
    component: CapturePolicy,
  },

  {
    id: uuidv4,
    path: "/add-capture-policy",
    component: AddCapturePolicy,
  },

  {
    id: uuidv4,
    path: "/capture-policy-details",
    component: CapturePolicyDetails,
  },

  {
    id: uuidv4,
    path: "/penialization-policy",
    component: PenalizationPolicy,
  },

  {
    id: uuidv4,
    path: "/add-penalization-policy",
    component: AddPenalizationPolicy,
  },

  {
    id: uuidv4(),
    path : "/penalization-policy-details",
    component: PenalizationPolicyDeails
  },

  {
    id: uuidv4(),
    path : "/overtime",
    component: Overtime
  },
  {
    id: uuidv4(),
    path : "/overtimeApprovals",
    component: OvertimeApprovals
  },
  {
    id : uuidv4(),
    path : "/overtime-details",
    component : OvertimeDetails
  },
  {
    id : uuidv4(),
    path : "/overtime-details/view/:overtimePolicyId",
    component : OvertimeDetailsViews
  },

  {
    id : uuidv4(),
    path : "/overtime-view/:overtimePolicyId",
    component : OvertPolicyViews
  },

  {
    id : uuidv4(),
    path : "/overtimePolicyAllocation",
    component : OvertimePolicyAllocation
  },

  //Organisation Band
  {
    id: uuidv4(),
    path: "/organisation/band",
    component: Band,
  },

  //Organisation Buisness Unit
  {
    id: uuidv4(),
    path: "/organisation/business-unit",
    component: BusinessUnit,
  },

  //Organisation Cost Center
  {
    id: uuidv4(),
    path: "/organisation/cost-center",
    component: CostCenter,
  },

  //Organisation Department
  {
    id: uuidv4(),
    path: "/organisation/department",
    component: Department,
  },

  //Organisation Division
  {
    id: uuidv4(),
    path: "/organisation/division",
    component: Division,
  },

  //Organisation Legal Entity
  {
    id: uuidv4(),
    path: "/organisation/legal-entity",
    component: LegalEntity,
  },
  {
    id: uuidv4(),
    path: "/organisation/legal-entity-view/:legalEntityId",
    component: ViewLegalEntity,
  },
  {
    id: uuidv4(),
    path: "/organisation/legal-entity-edit/:legalEntityId",
    component: EditLegalEntity,
  },
  {
    id: uuidv4(),
    path: "/organisation/legal-entity-add",
    component: AddLegalEntity,
  },
  //Organisation Location
  {
    id: uuidv4(),
    path: "/organisation/location",
    component: OrganisationLocation,
  },
  {
    id: uuidv4(),
    path: "/organisation/location-view/:locationId",
    component: ViewOrganisationLocation,
  },
  {
    id: uuidv4(),
    path: "/organisation/location-edit/:locationId",
    component: EditOrganisationLocation,
  },
  {
    id: uuidv4(),
    path: "/organisation/location-add",
    component: AddOrganisationLocation,
  },
  //Organisation Pay Grades
  {
    id: uuidv4(),
    path: "/organisation/pay-grades",
    component: PayGrades,
  },

  //Organisation Sub Department
  {
    id: uuidv4(),
    path: "/organisation/sub-department",
    component: SubDepartment,
  },
];

export default routes;
