import api, { commonThenResult } from './mtrack';

export const END_POINTS = {
  CITIES: () => '/api/common/common-routes/city',
  STATES: () => '/api/common/common-routes/state',
  UPLOAD_SINGLE_FILE: () => '/api/common/common-routes/upload-single',
};

export const listCities = async (inputData) => {
  const response = await api.get(END_POINTS.CITIES(), {
    params: {
      stateId: inputData,
    },
  });

  return commonThenResult(response);
};

export const listStates = async (inputData) => {
  const response = await api.get(END_POINTS.STATES(), {
    params: {
      countryId: inputData,
    },
  });

  return commonThenResult(response);
};

export const uploadSingleFile = async (inputData) => {
  const response = await api.post(END_POINTS.UPLOAD_SINGLE_FILE(), inputData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return commonThenResult(response);
};
