export const INITIAL_DATA = {
  cinNum: "",
  businessName: "",
  panNum: "",
  linNum: "",
  legalStatus: "",
  natureOfBusiness: "",
  email: "",
};

export const legalStatus = [
  { id: 1, name: "Private Limited company" },
  { id: 2, name: "Public Limited company" },
  { id: 3, name: "Limited Liability Partnership" },
  { id: 4, name: "Sole Proprietorship" },
  { id: 5, name: "Partnership" },
];
