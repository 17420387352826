import React from 'react';

function FormCard(props) {
  return (
    <div
      className={`px-8 py-2 ${
        props.className ? props.className : null
      } bg-white dark:bg-gray-800`}
    >
      {props.children}
    </div>
  );
}

export default FormCard;
