import React, { useEffect, useState } from "react";
import { PageTitle } from "@futurionic/ui";
import { Card } from "../../../components/UI Components";
import OvertimeApprovalTable from "./../components/overtimeApprovalTable";
import SelectBoxOvertimeApprovals from "../components/selectBox";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { getAllApprovalTasks } from "../api/overtimeRequests";
import Swal from "sweetalert2";
import { listLocation } from "../../location/api/location";
import { listDepartment } from "../../department/api/department";
import useDebounce from "../../../hooks/useDebounce";

const OvertimeApprovals = () => {
  const { t } = useTranslation();
  const { companyId } = useSelector((state) => state.companyData);
  const employeeId = useSelector((state) => state.employeeFormData.employeeId);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [overtimeType, setOvertimeType] = useState("");
  const [termName, setTermName] = useState("");

  const debouncedEmployeeName = useDebounce(termName, 500);
  //  console.log(employeeId);

  // const employeeId = 26;
  const requestTypeId = [10, 11];

  // Fetch approval tasks using useQuery
  const {
    data: approvalTaskData,
    isLoading,
    error,
    refetch,
  } = useQuery(
    [
      "getAllApprovalTasksDetails",
      companyId,
      requestTypeId,
      selectedLocation,
      selectedDepartment,
      startDate,
      endDate,
      debouncedEmployeeName,
      overtimeType,
    ],
    () =>
      getAllApprovalTasks({
        companyId,
        requestTypeId,
        selectedLocation,
        departmentName: selectedDepartment,
        startDate,
        endDate,
        employeeName: debouncedEmployeeName,
        overtimeType,
      }),
    {
      enabled: !!companyId && !!requestTypeId.length,
    }
  );

  // Fetch locations for select box
  const { data: locations, isLoading: isLoadingLocations } = useQuery(
    ["locations", companyId, termName],
    () => listLocation({ companyId, termName }),
    {
      enabled: !!companyId,
    }
  );

  // Fetch departments for select box
  const {
    data: departments,
    isLoading: isLoadingDepartments,
    error: departmentError,
  } = useQuery(
    ["departments", companyId],
    () => listDepartment({ companyId, status: "active" }),
    { enabled: !!companyId }
  );

  // Error handling for departments
  useEffect(() => {
    if (departmentError) {
      Swal.fire({
        title: "Error",
        text: "Failed to load departments",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }, [departmentError]);

  // Re-fetch approval tasks whenever filters change
  useEffect(() => {
    if (companyId) {
      refetch();
    }
  }, [
    selectedLocation,
    selectedDepartment,
    startDate,
    endDate,
    debouncedEmployeeName,
    companyId,
    overtimeType,
    refetch,
  ]);

  return (
    <>
      <div className="shadow-none">
        <PageTitle>{t("OVERTIME_APPROVALS")}</PageTitle>
        <SelectBoxOvertimeApprovals
          setSelectedLocation={setSelectedLocation}
          setSelectedDepartment={setSelectedDepartment}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setOvertimeType={setOvertimeType}
          locations={locations || []}
          isLoadingLocations={isLoadingLocations}
          departments={departments || []}
          isLoadingDepartments={isLoadingDepartments}
          setTermName={setTermName}
          startDate={startDate}
          endDate={endDate}
        />
        <OvertimeApprovalTable
          approvalTaskData={approvalTaskData?.approvalTasks || []}
          loading={isLoading}
          refetchApprovalTasks={refetch}
        />
      </div>
    </>
  );
};

export default OvertimeApprovals;
