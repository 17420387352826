import React, { useState } from "react";
import { Button } from "@windmill/react-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import FormCard from "../../../components/UI Components/wrappers/FormCard";
import MultiSteps from "../../../components/UI Components/MultiSteps";
import DocumentDetails from "../pages/Add/DocumentDetails";
import AddressForm from "../pages/Add/AddressForm";
import RegisterVendor from "../RegisterVendor/RegisterVendor";
import { PageTitle } from "@futurionic/ui";

function VendorSignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [choosen, setChoosen] = useState("Basic");
  const [vid, setVid] = useState(null);
  const [empanelmentId, setEmpanlmentId] = useState(null);

  const isClicked = (param) => {
    setChoosen((prevChoosen) => {
      console.log("Before setChoosen:", prevChoosen);
      console.log("After setChoosen:", param);
      return param;
    });
  };

  const handleNext = ({ vendorId, vendorEmpanelmentId }) => {
    setVid(vendorId);
    setEmpanlmentId(vendorEmpanelmentId);

    setChoosen((prevChoosen) => {
      if (prevChoosen === "Basic") {
        return "Document";
      }
      if (prevChoosen === "Document") {
        return "Address";
      }
      if (prevChoosen === "Address") {
        Swal.fire({
          title: "Success!",
          text: "Vendor Successfully Added",
          icon: "success",
          confirmButtonText: "Ok",
        });

        navigate(`/app/view-vendor/${vendorId}`);
      }
      return prevChoosen; // Return the previous state if no condition is met
    });
  };

  const buttons = (handleCancel, isSubmitting) => (
    <div className="flex items-center justify-center mb-4 mt-8">
      <Button
        type="button"
        className="mr-4 -ml-12"
        layout="outline"
        onClick={handleCancel}
      >
        {t("CANCEL")}
      </Button>
      {(choosen === "Basic" || choosen === "Document") && (
        <Button type="submit" disabled={isSubmitting}>
          {t("NEXT")}
        </Button>
      )}
      {choosen === "Address" && (
        <Button type="submit" disabled={isSubmitting}>
          {t("SAVE")}
        </Button>
      )}
    </div>
  );

  return (
    <>
      <main className="flex items-center justify-center px-8 py-2 ">
        <FormCard className="w-full rounded-lg shadow-md">
          <PageTitle>{t("COMPANY REGISTRATION")}</PageTitle>
          <MultiSteps checkClick={isClicked} choosen={choosen} />
          {choosen === "Basic" && (
            <RegisterVendor buttons={buttons} next={handleNext} />
          )}
          {choosen === "Document" && (
            <DocumentDetails
              buttons={buttons}
              next={handleNext}
              vendorId={vid}
              empanelmentId={empanelmentId}
            />
          )}
          {choosen === "Address" && (
            <AddressForm
              isHQ={true}
              buttons={buttons}
              next={handleNext}
              vendorId={vid}
              empanelmentId={empanelmentId}
            />
          )}
        </FormCard>
      </main>
    </>
  );
}

export default VendorSignUp;
