import React from "react";
import DatePicker from "react-datepicker";
import { SearchIcon } from "@heroicons/react/solid";
import { Input } from "@windmill/react-ui";
import { useTranslation } from "react-i18next";
import LocationSelect from "./LocationSelect";
import DepartmentSelect from "./DepartmentSelect";
import RequestTypeSelect from "./RequestType";

function SelectBoxOvertimeApprovals({
  setSelectedLocation,
  setSelectedDepartment,
  setStartDate,
  setEndDate,
  setOvertimeType,
  setTermName,
  locations,
  isLoadingLocations,
  departments,
  isLoadingDepartments,
  startDate,
  endDate,
}) {
  const { t } = useTranslation();

   return (
    <div className="space-y-4">
      {/* Top Row: Date Pickers, Location, Request Type, Department */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {/* From Date Picker */}
        <div>
          {/* <label className="block text-sm font-medium text-gray-700">
            {t("From Date")}
          </label> */}
          <input
            type="date"
            className="border-2 w-full h-10 px-2 outline-none focus:border-purple-400/60 py-2 cursor-pointer"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Select from date"
          />
        </div>

        {/* To Date Picker */}
        <div>
          {/* <label className="block text-sm font-medium text-gray-700">
            {t("To Date")}
          </label> */}
          <input
            type="date"
            className="border-2 w-full h-10 px-2 outline-none focus:border-purple-400/60 py-2 cursor-pointer"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="Select to date"
            max={new Date().toISOString().split("T")[0]}
          />
        </div>

        {/* Location select component */}
        {/* <LocationSelect
          setSelectedLocation={setSelectedLocation}
          companyLocations={locations}
          status={isLoadingLocations ? 'loading' : 'success'}
        /> */}

        {/* Request type select component */}
        <RequestTypeSelect setOvertimeType={setOvertimeType} />

        {/* Department select component */}
        <DepartmentSelect
          setSelectedDepartment={setSelectedDepartment}
          departments={departments}
          status={isLoadingDepartments ? "loading" : "success"}
        />
      </div>

      {/* Search input */}
      <div className="relative w-full max-w-xl mr-6 focus-within:text-purple-500">
        <Input
          className="pl-3 border text-gray-700"
          placeholder={t("EMPLOYEE NAME")}
          aria-label="Search"
          onChange={(e) => setTermName(e.target.value)}
        />
        <div className="absolute inset-y-0 right-2 flex items-center pl-2">
          <SearchIcon className="w-4 h-4" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
}

export default SelectBoxOvertimeApprovals;
