import baseAPIFactory, { commonThenResult } from '../../../api/mtrack';

export const END_POINTS = {
  ADD_LOCATION: () => '/api/company-location/location/add',
  LIST_LOCATION: () => '/api/company-location/location/list',
  GET_LOCATION: () => '/api/company-location/location',
  UPDATE_LOCATION: () => '/api/company-location/location/update',
  ADD_VENDOREMPNELMENTLOCATION: () =>
    '/api/company-location/vendorEmpanelmentLocations/add',
  GET_VENDOREMPNELMENTLOCATION: () =>
    '/api/company-location/vendorEmpanelmentLocations',
  UPDATE_VENDOREMPNELMENTLOCATION: () =>
    '/api/company-location/vendorEmpanelmentLocations/update',
  CHECK_EMPLOYEE_EXISTS: () =>
    '/api/employee/employee-basic/check-employee-adhaar',


  ADD_LOCATION_LIST: () => '/api/company-location/location-bulk/add',
  BULK_LOCATION_FORMAT: () => '/api/company-location/location-bulk/format',


};

//get all  location
export const listLocation = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.LIST_LOCATION(), {
    params: {
      companyId: inputData.companyId,
      termName: inputData.termName,
      termCity: inputData.termCity,
    },
  });
  return commonThenResult(response);
};

//get specific location
export const getLocationById = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_LOCATION(), {
    params: {
      locationId: inputData,
    },
  });
  return commonThenResult(response);
};

//add new location
export const addLocation = async (inputData) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_LOCATION(),
    inputData
  );
  return commonThenResult(response);
};

//update location
export const updateEntityLocation = async (inputData) => {
  const response = await baseAPIFactory.patch(
    END_POINTS.UPDATE_LOCATION(),
    inputData
  );
  return commonThenResult(response);
};

//add new vendorEmpanelment
export const addVendorEmpnelmentLocation = async (inputData) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_VENDOREMPNELMENTLOCATION(),
    inputData
  );
  return commonThenResult(response);
};
export const updateVendorEmpnelmentLocation = async (inputData) => {
  const response = await baseAPIFactory.patch(
    END_POINTS.UPDATE_VENDOREMPNELMENTLOCATION(),
    inputData
  );
  return commonThenResult(response);
};
export const getVendorEmpnelmentLocation = async (inputData) => {
  console.log(inputData);
  const response = await baseAPIFactory.get(
    END_POINTS.GET_VENDOREMPNELMENTLOCATION(),
    {
      params: {
        limit: inputData.limit,
        offset: inputData.offset,
        locationId: inputData.locationId,
      },
    }
  );
  return commonThenResult(response);
};

export const checkIfEmployeeExist = async (inputData) => {
  const { aadhar } = inputData;
  const response = await baseAPIFactory.get(
    END_POINTS.CHECK_EMPLOYEE_EXISTS(),
    {
      params: {
        aadhar,
      },
    }
  );
  return commonThenResult(response);
};






export const addLocationList = async (inputData) => {
  const result_1 = await baseAPIFactory.post(END_POINTS.ADD_LOCATION_LIST(), inputData);
  return commonThenResult(result_1);
};

export const getLocationBulkFormat = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.BULK_LOCATION_FORMAT(), {
    params: {
      title: inputData,
    },
  });
  console.log(response);
  return commonThenResult(response)

}
