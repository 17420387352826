import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companyId: null,
  name: '',
  email: '',
  cinNum: '',
  legalStatus: '',
  natureOfBusiness: '',
};

const companyData = createSlice({
  name: 'companyData',
  initialState,
  reducers: {
    captureCompany(state, action) {
      state.companyId = action.payload.id;
      state.name = action.payload.name;
      state.cinNum = action.payload.cinNum; 
      state.email = action.payload.email;
      state.legalStatus = action.payload.legalStatus;
      state.natureOfBusiness = action.payload.natureOfBusiness;
    },
  },
});

export const { captureCompany } = companyData.actions;

export default companyData.reducer;
