import { createSlice } from "@reduxjs/toolkit";


const navSlice = createSlice({
    name :"navbar",
    initialState : {
        navbar : null,
        active : '',
    },
    reducers : {
        setNavArray : (state , action) => {
            state.navbar = action.payload
            console.log(state.navbar)
        },
        setActive : (state ,action) => {
            state.active = action.payload
        }
    }
})


export const {setNavArray , setActive} = navSlice.actions

export default navSlice.reducer