import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "@windmill/react-ui";

function DepartmentSelect({ setSelectedDepartment, departments, status }) {
  const { t } = useTranslation();

  return (
    <div className="w-48 mr-4">
      <Select
        className=" mb-6 border"
        onChange={(e) => {
          setSelectedDepartment(e.target.value);
        }}
      >
        <option hidden>{t("Department")}</option>{" "}
        {/* Translation for 'Department' */}
        <option value="">--- {t("All")} ---</option>{" "}
        {/* Option to select all departments */}
        {status === "success" &&
          departments.map((department) => (
            <option key={department.id} value={department.id}>
              {`${department.name} (${department.departmentCode})`}{" "}
              {/* Display department name and code */}
            </option>
          ))}
      </Select>
    </div>
  );
}

export default DepartmentSelect;
