import React, { useMemo, useState } from "react";
import LegalEntityForm from "../../forms/LegalEntity/LegalEntityForm";
import {
  createBand,
  createBusinessUnit,
  createCostCenter,
  createDepartment,
  createDivision,
  createLegalEntity,
  createLocation,
  createPayGrade,
  createSubDepartment,
  updateBand,
  updateBusinessUnit,
  updateCostCenter,
  updateDepartment,
  updateDivision,
  updateLegalEntity,
  updateLocation,
  updatePayGrade,
  updateSubDepartment,
} from "../../api/organisation";
import Swal from "sweetalert2";
import appLogger from "../../../../config/appLogger";
import { useNavigate } from "react-router-dom";
import PayGradeBandForm from "../../forms/PayGradeBand/PayGradeBandForm";
import DeptDivBUCCForm from "../../forms/DeptDivBUCC/DeptDivBUCCForm";
import LocationForm from "../../forms/Location/LocationForm";
import SubDepartmentForm from "../../forms/SubDepartment/SubDepartmentForm";
import { useDispatch, useSelector } from "react-redux";
import { PageTitle } from "@futurionic/ui";
import { Button } from "@windmill/react-ui";
import { useTranslation } from "react-i18next";
import { captureCompany } from "../../../../store/companySlice";

const formConfig = {
  legalEntity: {
    component: LegalEntityForm,
    title: "ADD LEGAL_ENTITY",
    action: createLegalEntity,
    update: updateLegalEntity,
  },
  businessUnit: {
    component: DeptDivBUCCForm,
    title: "ADD BUSINESS UNIT",
    action: createBusinessUnit,
    update: updateBusinessUnit,
    entityType: "businessUnit",
  },
  division: {
    component: DeptDivBUCCForm,
    title: "ADD DIVISION",
    action: createDivision,
    update: updateDivision,
    entityType: "division",
  },
  department: {
    component: DeptDivBUCCForm,
    title: "ADD DEPARTMENT",
    action: createDepartment,
    update: updateDepartment,
    entityType: "department",
  },
  subDepartment: {
    component: SubDepartmentForm,
    title: "ADD SUB DEPARTMENT",
    action: createSubDepartment,
    update: updateSubDepartment,
  },
  location: {
    component: LocationForm,
    title: "ADD LOCATION",
    action: createLocation,
    update: updateLocation,
  },
  costCenter: {
    component: DeptDivBUCCForm,
    title: "ADD COST CENTER",
    action: createCostCenter,
    update: updateCostCenter,
    entityType: "costCenter",
  },
  payGrade: {
    component: PayGradeBandForm,
    title: "ADD PAY GRADE",
    action: createPayGrade,
    update: updatePayGrade,
    entityType: "payGrade",
  },
  band: {
    component: PayGradeBandForm,
    title: "ADD BAND",
    action: createBand,
    update: updateBand,
    entityType: "band",
  },
};

const OrganisationSetupComponent = ({
  businessUnit,
  division,
  multipleLocation,
  primaryUserId,
  isGroupEntity,
  setStartingSetup,
}) => {
  const [choosen, setChoosen] = useState("legalEntity");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [initailData, setInitailData] = useState({});

  const dispatch = useDispatch();
  const [companyId, setCompanyId] = useState(null);
  const fieldCount = useMemo(() => {
    if (choosen === "legalEntity" && choosen === "location") {
      return 8;
    } else {
      return 4;
    }
  }, [choosen]);

  const handleSubmit = async (values) => {
    const { action, update } = formConfig[choosen];
    let data = { ...values };

    // Configure data based on the chosen entity type
    if (choosen === "legalEntity") {
      Object.assign(data, {
        isVendor: true,
        isClient: true,
        isGroupEntity: false,
        primaryUserId,
      });
    } else if (
      ["band", "costCenter", "payGrade", "division"].includes(choosen)
    ) {
      data.companyId = companyId;
    } else if (
      ["businessUnit", "subDepartment", "location", "department"].includes(
        choosen
      )
    ) {
      data.entityId = companyId;
    }

    try {
      const idKey = `${choosen}Id`;
      if (initailData[choosen]?.id) {
        data[idKey] = initailData[choosen]?.id;
      }

      const res = await (data[idKey] ? update(data) : action(data));
      const result = res[choosen];

      if (result?.err) {
        throw new Error(result.err.errors[0].message);
      }

      setInitailData((prev) => ({
        ...prev,
        [choosen]: {
          ...result,
          address: {
            ...result.address,
            stateId: parseInt(result.address?.stateId) || null,
            cityId: parseInt(result.address?.cityId) || null,
          },
        },
      }));
      if (choosen === "legalEntity") {
        setCompanyId(result.id);
        dispatch(captureCompany(result));
      }

      Swal.fire({
        title: "Success!",
        text: `${t("SUCCESS_MESSAGE", { entity: choosen })} Added`,
        icon: "success",
        confirmButtonText: "Ok",
      });

      // Determine the next entity
      const nextEntityMap = {
        legalEntity: businessUnit
          ? "businessUnit"
          : division
          ? "division"
          : "department",
        businessUnit: division ? "division" : "department",
        division: "department",
        department: "subDepartment",
        subDepartment: multipleLocation ? "location" : "costCenter",
        location: "costCenter",
        costCenter: "payGrade",
        payGrade: "band",
        band: null,
      };

      const nextEntity = nextEntityMap[choosen];
      nextEntity
        ? setChoosen(nextEntity)
        : navigate(`/app/organisation/legal-entity`);
    } catch (err) {
      appLogger.error(err);
      Swal.fire({
        title: "Error!",
        text: "An error occurred while adding the entity.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  const handleCancel = () => {
    const prevEntityMap = {
      legalEntity: null,
      businessUnit: "legalEntity",
      division: businessUnit ? "businessUnit" : "legalEntity",
      department: division
        ? "division"
        : businessUnit
        ? "businessUnit"
        : "legalEntity",
      subDepartment: "department",
      location: "subDepartment",
      costCenter: multipleLocation ? "location" : "subDepartment",
      payGrade: "costCenter",
      band: "payGrade",
    };

    const prevEntity = prevEntityMap[choosen];
    if (prevEntity) {
      setChoosen(prevEntity);
    } else {
      setStartingSetup(true);
    }
  };
  const buttons = (isSubmitting) => (
    <div className="flex items-center justify-center mb-4 mt-8">
      <Button
        type="button"
        className="mr-4 -ml-12"
        layout="outline"
        onClick={handleCancel}
      >
        {t("CANCEL")}
      </Button>
      {choosen !== "band" && (
        <Button type="submit" disabled={isSubmitting}>
          {t("NEXT")}
        </Button>
      )}
      {choosen === "band" && (
        <Button type="submit" disabled={isSubmitting}>
          {t("SAVE")}
        </Button>
      )}
    </div>
  );

  const { component: FormComponent, title, entityType } = formConfig[choosen];

  return (
    <div className=" h-screen ml-20  flex flex-col ">
      <PageTitle>{t(title).toUpperCase()}</PageTitle>
      <div
        className={`${
          fieldCount === 4 ? "flex justify-center items-center h-full " : ""
        } `}
      >
        <FormComponent
          handleFormSubmit={handleSubmit}
          entityType={entityType}
          initialValue={initailData[choosen]}
          buttons={buttons}
        />
      </div>
    </div>
  );
};

export default OrganisationSetupComponent;
