import axios from 'axios';
import Config from '../config/config';
import { store } from '../store/store';
import { logIn } from '../store/authenticationSlice';

const mtrack = axios.create({
  baseURL: Config.getConfig().BASE_URL,
  withCredentials: true,
});

mtrack.interceptors.request.use(
  (config) => {
    const token = store.getState().authentication.token;
    if (token) {
      config.headers['x-access-token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

mtrack.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // console.log('cok', Cookies.get());

    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const response = await mtrack.post(
        '/api/user/auth-refresh',
        {},
        {
          headers: {
            refresh_token: localStorage.getItem('refresh-token'),
          },
        }
      );

      const token = response.data.token;
      store.dispatch(logIn(token));
      mtrack.defaults.headers.common['x-access-token'] = token;
      return await mtrack(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const commonThenResult = (result) => result.data;

export default mtrack;
