import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
} from "@windmill/react-ui";
import { AddIcon, EditIcon, ViewIcon } from "../../../assets/asset";
import { isEmpty } from "lodash";

const ModalClose = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  useEffect(() => {
    closeModal();
  }, [props.closer]);

  const handlerModalSubmit = () => {
    setTimeout(() => {
      closeModal();
    }, 2000);
  };

  return (
    <>
      <div>
        {props.icon === "Add" && (
          <Label
            className="px-4 py-3 my-1 text-blue-500 hover:cursor-pointer"
            onClick={openModal}
          >
            <AddIcon className="w-5 h-5 mr-2 inline" aria-hidden="true" />
            {props.iconTitle}
          </Label>
        )}
        {props.icon === "View" && (
          <Label
            className="px-4 py-3 my-1 text-blue-500 hover:cursor-pointer"
            onClick={openModal}
          >
            <ViewIcon className="w-5 h-5 mr-2 inline" aria-hidden="true" />
            {props.iconTitle}
          </Label>
        )}
        {props.icon === "Edit" && (
          <Label
            className={`${props.className} px-4 py-3 my-1 text-blue-500 hover:cursor-pointer`}
            onClick={openModal}
          >
            <EditIcon className="w-5 h-5 mr-2 inline" aria-hidden="true" />
            {props.iconTitle}
          </Label>
        )}
        {props.icon === "None" && (
          <Label
            className={`${props.className} px-4 py-3 my-1 text-blue-500 hover:cursor-pointer`}
            onClick={openModal}
          >
            {props.iconTitle}
          </Label>
        )}
        {isEmpty(props.icon) && (
          <Button onClick={openModal}>{props.title}</Button>
        )}
      </div>

      <Modal
        {...(props.styles && { className: props.styles })}
        isOpen={isModalOpen}
        onClose={closeModal}
      >
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>{props.children}</ModalBody>
        {!props.component && (
          <ModalFooter>
            <>
              <div className="sm:block hidden ">
                <Button layout="outline" onClick={closeModal}>
                  Cancel
                </Button>
              </div>
              <div className="sm:block hidden ">
                <Button
                  form={props.formId}
                  type="submit"
                  // onClick={handlerModalSubmit}
                  {...(props.handlerModalSubmit && {
                    onClick: props.handlerModalSubmit,
                  })}
                >
                  {props.submitButton ? props.submitButton : "Save"}
                </Button>
              </div>
              <div className="block w-full sm:hidden">
                <Button
                  block
                  size="large"
                  layout="outline"
                  onClick={closeModal}
                >
                  Cancel
                </Button>
              </div>
              <div className="block w-full sm:hidden">
                <Button
                  block
                  form={props.formId}
                  size="large"
                  type="sumbit"
                  {...(props.handlerModalSubmit && {
                    onClick: props.handlerModalSubmit,
                  })}
                  // onClick={handlerModalSubmit}
                >
                  {props.submitButton ? props.submitButton : "Save"}
                </Button>
              </div>
            </>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};

ModalClose.defaultProps = {
  icon: "",
  className: "",
  footerButtons: false,
};

ModalClose.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
  footerButtons: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
    PropTypes.bool,
  ]),
};

export default ModalClose;
