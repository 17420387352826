import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "@windmill/react-ui";

function LocationSelect({ setSelectedLocation, companyLocations, status }) {
  const { t } = useTranslation();

  return (
    <div className="w-48 mr-4">
      <Select
        className="mt-1 mb-6 border"
        onChange={(e) => {
          setSelectedLocation(e.target.value); // Update selected location when option changes
        }}
      >
        <option hidden>{t("Location")}</option> {/* Default hidden option */}
        <option value="">--- {t("All")} ---</option>{" "}
        {/* Option for 'All' locations */}
        
        {/* Render loading state */}
        {status === "loading" ? (
          <option disabled>{t("Loading locations...")}</option>
        ) : status === "success" && companyLocations.length > 0 ? (
          companyLocations.map((location) => (
            <option key={location.id} value={location.id}>
              {`${location.name} (${location.locationCode})`}
            </option>
          ))
        ) : (
          <option disabled>{t("No locations available")}</option> /* In case no locations are found */
        )}
      </Select>
    </div>
  );
}

export default LocationSelect;
