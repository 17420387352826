import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'hi'];

i18n
  .use(initReactI18next) // load translations using http (default                                               public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(HttpApi) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.

    detection: {
      supportedLngs: availableLanguages,
      checkWhitelist: true, // options for language detection
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },

    debug: false,
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },

    whitelist: availableLanguages,
  });

export default i18n;
