// import React from 'react';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';

// import { logIn } from '../../../store/authenticationSlice';
// import { captureCompany } from '../../../store/companySlice';

// import { registerApi } from '../api/User';
// import FormikSignUpForm from '../forms/signUp';

// function SignUp() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const handleSubmit = async ({ firstName, email, password }) => {
//     console.log('clicked SignUp');

//     try {
//       const responce = await registerApi({
//         firstName,
//         email,
//         password,
//       });
//       // sessionStorage.setItem('token', responce.data.token);

//       dispatch(logIn(responce.data.token));
//       dispatch(captureCompany(responce.data.userData.company));
//       navigate('/app', { replace: true });
//     } catch (err) {
//       alert(err.response.data);
//     }
//   };

//   return <FormikSignUpForm handleFormSubmit={handleSubmit} />;
// }

// export default SignUp;

import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logIn } from "../../../store/authenticationSlice";
import { captureCompany } from "../../../store/companySlice";

import { registerApi } from "../api/User";
import FormikSignUpForm from "../forms/signUp";

function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async ({ firstName, email, password }) => {
    console.log("clicked SignUp");

    try {
      const response = await registerApi({
        firstName,
        email,
        password,
      });

      console.log("API Response:", response);

      const token = response?.data?.token?.accessToken; // Accessing accessToken property
      const company = response?.data?.user?.fullName;

      if (token && company) {
        dispatch(logIn(token));
        dispatch(captureCompany(company));
        // navigate('/vendor-register', { replace: true });
        navigate("/organisation/setup", {
          state: {
            userName: firstName,
            primaryUserId: response?.data?.user?.id,
          },
          replace: true,
        });
      } else {
        console.error("Token or company is missing in the API response.");
      }
    } catch (err) {
      alert(err.response.data);
    }
  };

  return <FormikSignUpForm handleFormSubmit={handleSubmit} />;
}

export default SignUp;
