import React, { useState, useEffect } from 'react';

function MultiSteps({ checkClick, choosen }) {
  const [click, SetClick] = useState('Basic');
  const onClickHandleB = () => {
    SetClick('Basic');
  };
  const onClickHandleD = () => {
    SetClick('Document');
  };
  const onClickHandleA = () => {
    SetClick('Address');
  };

  useEffect(() => {
    checkClick(click);
  }, [click]);

  useEffect(() => {
    SetClick(choosen);
  }, [choosen]);

  return (
    <>
      <div>
        <h2 className="sr-only">Steps</h2>

        <div>
          <ol className="my-4 grid grid-cols-1 overflow-hidden text-sm text-indigo-500 border border-indigo-100 divide-x divide-indigo-100 rounded-lg sm:grid-cols-3">
            <li
              className={` ${
                click === 'Basic'
                  ? 'transition ease-in-out delay-150 scale-y-125 bg-indigo-200'
                  : 'bg:white'
              } flex items-center justify-center p-4  hover:cursor-pointer `}
              // onClick={onClickHandleB}
              // disabled={true}
            >
              <p className="leading-none">
                <strong className="block font-medium"> Details </strong>
                <small className="mt-1"> Enter Basic information </small>
              </p>
            </li>

            <li
              className={` relative ${
                click === 'Document'
                  ? 'transition ease-in-out delay-150 scale-y-125 bg-indigo-200'
                  : 'bg:white'
              } flex items-center justify-center p-4  hover:cursor-pointer `}
              // onClick={onClickHandleD}
            >
              <span
                className={`absolute hidden w-4 h-4 rotate-45 -translate-y-1/2 ${
                  click === 'Basic' ? 'bg-white' : 'bg-indigo-50'
                }  border border-b-0 border-l-0 border-indigo-100 sm:block -left-2 top-1/2`}
              ></span>

              <span
                className={`absolute hidden w-4 h-4 rotate-45 -translate-y-1/2 ${
                  click === 'Basic' ? 'bg-white' : 'bg-indigo-50'
                } border border-b-0 border-l-0 border-indigo-100 sm:block -right-2 top-1/2`}
              ></span>

              <p className="leading-none">
                <strong className="block font-medium"> Document </strong>
                <small className="mt-1"> Enter Document Details </small>
              </p>
            </li>

            <li
              className={` flex ${
                click === 'Address'
                  ? 'transition ease-in-out delay-150 scale-y-125 bg-indigo-200'
                  : 'bg:white'
              } flex items-center justify-center p-4 hover:cursor-pointer `}
              // onClick={onClickHandleA}
            >
              <p className="leading-none">
                <strong className="block font-medium"> Address </strong>
                <small className="mt-1"> Enter Address </small>
              </p>
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}
export default MultiSteps;
