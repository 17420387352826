import * as Yup from "yup";

export const DeptDivBUCCSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  code: Yup.string().required("Code is required"),
});
export const locationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  locationCode: Yup.string().required("Code is required"),
  addressLine1: Yup.string().required("Address Line 1 is required"),
  addressLine2: Yup.string().required("Address Line 2 is required"),
  countryId: Yup.number()
    .required("Country Name is required")
    .min(1, "Country Name is required"),
  stateId: Yup.number().required("State is required"),
  cityId: Yup.number().required("City is required"),
  pincode: Yup.string()
    .matches(/^[0-9]{5,6}$/, "Zipcode should be 5 or 6 digits")
    .required("Zipcode is required"),
});
export const legalEntitySchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  registeredName: Yup.string().required("Registered Name is required"),

  dateOfIncorporation: Yup.string().required(
    "date Of Incorporation is required"
  ),
  cinNum: Yup.string().required("Company Incorporation Number is required"),
  panNum: Yup.string().required("Company PAN Number is required"),
  businessType: Yup.string().required("Business Type is required"),
  natureOfBusiness: Yup.string().required("Nature Of Business is required"),
  address: Yup.object().shape({
    addressLine1: Yup.string().required("Address Line 1 is required"),
    addressLine2: Yup.string().required("Address Line 2 is required"),
    countryId: Yup.number()
      .required("Country Name is required")
      .min(1, "Country Name is required"),
    stateId: Yup.number().required("State is required"),
    cityId: Yup.number().required("City is required"),
    pincode: Yup.string()
      .matches(/^[0-9]{5,6}$/, "Zipcode should be 5 or 6 digits")
      .required("Zipcode is required"),
    currency: Yup.string().required("Currency is required"),
    financialYear: Yup.string().required("financial Year is required"),
  }),
});
export const subDeptSchema = Yup.object().shape({
  departmentId: Yup.number().required("department is required"),
  name: Yup.string().required("Name is required"),
  code: Yup.string().required("Code is required"),
});
