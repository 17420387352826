import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import appLogger from '../../../../config/appLogger';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, isFunction } from 'lodash';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import {
  addVendorDocument,
  getVendorRequiredDocuments,
} from '../../api/vendor';
import { getData } from '../../forms/DocumentDetails/constants';
import FormikDocumentDetails from '../../forms/DocumentDetails';
import Spinner from '../../../../components/UI Components/Spinner';
import {
  nonRequiredDocuments,
  requiredDocuments,
} from '../../../../utils/commonSchema';
import { capturevendorFormData } from '../../../../store/vendorFormSlice';

function DocumentDetails({
  buttons,
  className,
  formId,
  vendorId,
  empanelmentId,
  next,
  reloadStateCallback,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { companyId } = useSelector((state) => state.companyData);

  const [data, setData] = useState([]);
  const [documentSchema, setDocumentSchema] = useState({});
  const [notLoading, setNotLoading] = useState(true);

  useEffect(() => {
    getVendorRequiredDocuments(companyId).then((result) => {
      result.sort((a, b) => {
        return a.weightSequenceVendor - b.weightSequenceVendor;
      });

      const names = result.map((i) => {
        return {
          title: i.DocumentType.documentName,
          valueKey: i.DocumentType.documentKey,
          isBack: i.DocumentType.documentHasBack,
          isExpire: i.DocumentType.documentHasExpiry,
          isValidCountryId: i.DocumentType.validCountryId,
          companyId: i.companyId,
          documentTypeId: i.documentTypeId,
          isMandatory: i.isMandatory,
        };
      });

      setData(names);
    });
  }, [companyId]);

  useEffect(() => {
    const schema = {};
    data.forEach((element) => {
      if (!element.isMandatory) {
        schema[element.valueKey] = nonRequiredDocuments[element.valueKey];
      }

      if (element.isMandatory) {
        schema[element.valueKey] =
          requiredDocuments[element.valueKey] ||
          Yup.string().required('Document is Required');
        if (element.isExpire) {
          schema[`${element.valueKey}Expiry`] =
            Yup.string().required('Enter Expiry Date');
        }

        //  files

        // schema[`${element.valueKey}Path`] =
        //   Yup.string().required('Upload File');
        // if (element.isBack) {
        //   schema[`${element.valueKey}BackPath`] =
        //     Yup.string().required('Upload File');
        // }
      }
    });
    setDocumentSchema(schema);
  }, [data]);

  const handleSubmit = async (values, options) => {
    setNotLoading(false);
    try {
      data.forEach(({ title, valueKey, documentTypeId, isMandatory }) => {
        const req = {
          documentName: title,
          documentValue: values[valueKey],
          vendorEntityId: vendorId,
          documentTypeId: documentTypeId,
          isVerified: false,
          isMandatory: isMandatory,
          documentFrontPath: values[`${valueKey}Path`],
          documentBackPath: values[`${valueKey}BackPath`],
          documentExpiry: values[`${valueKey}Expiry`],
        };
        addVendorDocument(req);
      });

      options.setSubmitting(false);
      const INITIAL_DATA = getData();

      dispatch(
        capturevendorFormData({
          vendorId: vendorId,
          vendorEmpanelmentId: empanelmentId,
          gstNum: values?.gstNumber,
        })
      );

      options.resetForm(INITIAL_DATA);
      if (isFunction(next))
        next({
          vendorId: vendorId,
          vendorEmpanelmentId: empanelmentId,
          gstNum: values?.gstNumber,
        });

      if (isFunction(reloadStateCallback)) reloadStateCallback(values);

      setNotLoading(true);
    } catch (err) {
      appLogger.log(err);
      Swal.fire({
        title: 'Error!',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      options.setSubmitting(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate('/app/vendor');
  };

  // console.log('EMP DOC', initData);

  return (
    <>
      {notLoading && !isEmpty(documentSchema) ? (
        <FormikDocumentDetails
          handleFormSubmit={handleSubmit}
          handleCancel={handleCancel}
          buttons={buttons}
          className={className}
          formRequired={data}
          formId={formId}
          vendorDocumentSchema={Yup.object(documentSchema)}
        />
      ) : (
        <Spinner className="absolute top-1/2 right-1/2" />
      )}
    </>
  );
}

export default DocumentDetails;
