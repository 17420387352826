import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import { Button, Input, Label, Select } from '@windmill/react-ui';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty, isFunction } from 'lodash';

import { listCities } from '../../../../api/common';
import { listDesignation } from '../../../../api/designation';

import useInitialData from '../../../../hooks/useInitialData';
import ErrorMessage from '../../../../components/UI Components/ErrorMessage';
import { vendorAddress } from '../schema';
import { INITIAL_DATA } from './constants';

const AddressForm = ({
  initialData,
  handleFormSubmit,
  handleCancel,
  buttons,
  states,
  formId,
}) => {
  const { t } = useTranslation();
  const { companyId } = useSelector((state) => state.companyData);
  const { gstNum } = useSelector((state) => state.vendorFormData);

  INITIAL_DATA.gstNum = gstNum;

  const { isDataEmpty, initialValues } = useInitialData({
    initialData,
    INITIAL_DATA,
  });

  const [cities, setCities] = useState([]);
  const [stopSelection, setStopSelection] = useState(true);
  const [designationOp, setDesignationOp] = useState([]);

  useEffect(() => {
    const fetchDesignation = async () => {
      const response = await listDesignation({ companyId });
      setDesignationOp(response);
    };
    fetchDesignation();
  }, [companyId]);

  const handleOnChange = async (event) => {
    setStopSelection(true);
    const city = await listCities(event.target.value);
    setCities(city);
    setStopSelection(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      enableReinitialize={isDataEmpty}
      validationSchema={vendorAddress}
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <form id={formId} autoComplete="off" onSubmit={handleSubmit}>
          <Label>
            <span>{t('ADDRESS_LINE_1')}*</span>
            <Field
              as={Input}
              className="mt-1 mb-6 border"
              placeholder={t('ENTER_ADDRESS')}
              name="addressLine1"
            />
          </Label>
          <ErrorMessage name="addressLine1" />
          <Label>
            <span> {t('ADDRESS_LINE_2')}*</span>
            <Field
              as={Input}
              className="mt-1 mb-6 border"
              placeholder={t('ENTER_ADDRESS')}
              name="addressLine2"
            />
          </Label>
          <ErrorMessage name="addressLine2" />

          <Label className="mt-1 mb-3">
            <span> {t('STATE')}*</span>
            <Select
              className="mt-1 mb-6 border"
              name="stateId"
              onChange={(event) => {
                setFieldValue('stateId', event.target.value);
                handleOnChange(event);
              }}
            >
              <option>Select State</option>
              {!isEmpty(states) &&
                states.map(({ id, name }) => {
                  return (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  );
                })}
            </Select>
          </Label>
          <ErrorMessage name="stateId" />

          <Label className="mt-1 mb-3">
            <span> {t('CITY')}*</span>
            <Field
              as={Select}
              className="mt-1 mb-6 border"
              name="cityId"
              disabled={stopSelection}
            >
              <option>Select City</option>
              {!isEmpty(cities) &&
                cities.map(({ id, name }) => {
                  return (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  );
                })}
            </Field>
          </Label>
          <ErrorMessage name="cityId" />
          <Label>
            <span> {t('PINCODE')}*</span>
            <Field
              as={Input}
              className="mt-1 mb-6 border"
              placeholder={t('ENTER_PINCODE')}
              name="pincode"
            />
          </Label>
          <ErrorMessage name="pincode" />
          <Label>
            <span> {t('TELEPHONE')}*</span>
            <Field
              as={Input}
              className="mt-1 mb-6 border"
              placeholder={t('ENTER_LANDLINE_NUMBER')}
              name="telephone"
            />
          </Label>
          <ErrorMessage name="telephone" />
          <Label>
            <span> {t('GST_NUMBER')}*</span>
            <Field
              as={Input}
              className="mt-1 mb-6 border"
              placeholder={t('ENTER_GST_NUMBER')}
              name="gstNum"
            />
          </Label>
          <ErrorMessage name="gstNum" />

          <Label>
            <span> {t('CONTACT_PERSON')}*</span>
            <Field
              as={Input}
              className="mt-1 mb-6 border"
              placeholder={t('ENTER_CONTACT_PERSON')}
              name="nameContact"
            />
          </Label>
          <ErrorMessage name="nameContact" />

          <Label>
            <span> {t('CONTACT_NUMBER')}*</span>
            <Field
              as={Input}
              className="mt-1 mb-6 border"
              placeholder={t('ENTER_MOBILE_NUMBER')}
              name="mobileContact"
            />
          </Label>
          <ErrorMessage name="mobileContact" />

          <Label>
            <span> {t('DESIGNATION')}*</span>
            <Field
              as={Input}
              className="mt-1 mb-6 border"
              placeholder={t('ENTER_DESIGNATION')}
              name="designationContact"
            />
          </Label>
          <ErrorMessage name="designationContact" />
          <Label>
            <span> {t('EMAIL')}*</span>
            <Field
              as={Input}
              className="mt-1 mb-6 border text-transform: lowercase"
              placeholder={t('ENTER_EMAIL')}
              name="emailContact"
            />
          </Label>
          <ErrorMessage name="emailContact" />
          {!buttons && (
            <div className="flex items-center justify-center mb-4 mt-8 ">
              <Button
                type="button"
                className="mr-4 -ml-12"
                layout="outline"
                onClick={handleCancel}
              >
                {t('CANCEL')}
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {t('SAVE')}
              </Button>
            </div>
          )}
          {isFunction(buttons) && buttons(handleCancel, isSubmitting)}
        </form>
      )}
    </Formik>
  );
};

AddressForm.defaultProps = {
  handleCancel: null,
  buttons: false,
};

AddressForm.propTypes = {
  initialData: PropTypes.instanceOf(Object),
  handleFormSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  buttons: PropTypes.any,
};

export default AddressForm;
