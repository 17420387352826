export const BUSINESS_UNIT_INITIAL_DATA = {
  name: "",
  code: "",
  description: "",
  businessUnitHead: [],
};
export const DIVISION_INITIAL_DATA = {
  name: "",
  code: "",
  description: "",
  divisionHead: [],
};
export const DEPARTMENT_INITIAL_DATA = {
  name: "",
  code: "",
  description: "",
  departmentHead: [],
};
export const COST_CENTER_INITIAL_DATA = {
  name: "",
  code: "",
  description: "",
  costCenterHead: [],
};
