import React, { useState } from "react";
import OrganisationSetupComponent from "../../component/OrganisationSetup/OrganisationSetupComponent";
import { useLocation } from "react-router-dom";
import { Button, Select as UiSelect } from "@windmill/react-ui";
import { ModalClose } from "../../../../components/UI Components";

const OrganisationSetup = () => {
  const location = useLocation();
  const [businessUnit, setBusinessUnit] = useState(true);
  const [division, setDivision] = useState(true);
  const [multipleLocation, setMultipleLocation] = useState(true);
  const [isGroupEntity, setIsGroupEntity] = useState(false);
  const [startingSetup, setStartingSetup] = useState(true);
  const { userName, primaryUserId } = location.state || {};
  const [closeM, setCloseM] = useState(Math.random());

  return (
    <>
      {startingSetup && (
        <div className="flex items-center justify-center h-screen">
          <div className="bg-white p-6 rounded-lg shadow-lg  w-full max-w-xl">
            <h1 className="text-center mb-4 text-lg font-semibold">
              Hi {"<"} {userName} {">"}, Complete Your Setup and Define
              Organisation Structure
            </h1>
            <div className="text-center flex flex-col items-center pb-6">
              <span>Are you a Group / Single Company ?? </span>
              <div className="w-96">
                <UiSelect
                  name="company"
                  className="border py-2 mt-1 mb-3 w-full block "
                  onChange={(event) => {
                    setIsGroupEntity(event.target.value);
                  }}
                >
                  <option key={"Single Company"} value={false}>
                    Single Company
                  </option>
                  <option key={"Group Company"} value={true}>
                    Group Company
                  </option>
                </UiSelect>
              </div>{" "}
            </div>
            <div className="form-control w-96 mx-auto">
              <label className="label cursor-pointer">
                <span className="label-text">Do You have Business Unit</span>
                <input
                  type="checkbox"
                  className="toggle"
                  onClick={() => {
                    setBusinessUnit(!businessUnit);
                  }}
                  defaultChecked={businessUnit}
                />
              </label>
              <label className="label cursor-pointer">
                <span className="label-text">Do You have Division</span>
                <input
                  type="checkbox"
                  className="toggle"
                  onClick={() => {
                    setDivision(!division);
                  }}
                  defaultChecked={division}
                />
              </label>
              <label className="label cursor-pointer">
                <span className="label-text">
                  Do You have Operation at Multiple Locations
                </span>
                <input
                  type="checkbox"
                  className="toggle"
                  onClick={() => {
                    setMultipleLocation(!multipleLocation);
                  }}
                  defaultChecked={multipleLocation}
                />
              </label>
            </div>
            <div className="flex justify-end mt-6">
              <ModalClose
                className="bg-[#1e429f] !text-white rounded-lg"
                iconTitle="Complete Setup"
                closer={closeM}
                component={true}
                icon="None"
              >
                <div>
                  <h1 className="font-bold text-center">Welcome to HR COSMO</h1>
                  <p className="text-center">
                    You can continue to the Control Panel. Based on your access,
                    you can define the Organisation Structure.
                  </p>
                  <br />
                  <p className="text-center">
                    After your trial ends, you can continue with our Pro plan or
                    choose our Plus or Free Plan.
                  </p>
                  <div className="flex justify-center mt-4">
                    <Button
                      onClick={() => {
                        setCloseM(Math.random());
                        setStartingSetup(false);
                      }}
                    >
                      Proceed to Organisation Setup
                    </Button>
                  </div>
                </div>
              </ModalClose>
            </div>
          </div>
        </div>
      )}
      {!startingSetup && (
        <OrganisationSetupComponent
          businessUnit={businessUnit}
          division={division}
          multipleLocation={multipleLocation}
          primaryUserId={primaryUserId}
          isGroupEntity={isGroupEntity}
          setStartingSetup={setStartingSetup}
        />
      )}
    </>
  );
};

export default OrganisationSetup;
