import baseAPIFactory, { commonThenResult } from "../../../api/mtrack";
export const END_POINTS = {
  GET_DEPARTMENT: () => "/api/organisation/department/get",
  ADD_DEPARTMENT: () => "/api/organisation/department/add",
  GET_DEPARTMENT_BY_ID: () => "/api/organisation/department/getById",
  EDIT_DEPARTMENT: () => "/api/organisation/department/updateById",
  DELETE_DEPARTMENT: () => "/api/organisation/department/delete",
  GET_BUSINESS_UNIT: () => "/api/organisation/business-unit/get",
  ADD_BUSINESS_UNIT: () => "/api/organisation/business-unit/add",
  GET_BUSINESS_UNIT_BY_ID: () => "/api/organisation/business-unit/getById",
  EDIT_BUSINESS_UNIT: () => "/api/organisation/business-unit/updateById",
  DELETE_BUSINESS_UNIT: () => "/api/organisation/business-unit/delete",
  GET_DIVISION: () => "/api/organisation/division/get",
  ADD_DIVISION: () => "/api/organisation/division/add",
  GET_DIVISION_BY_ID: () => "/api/organisation/division/getById",
  EDIT_DIVISION: () => "/api/organisation/division/updateById",
  DELETE_DIVISION: () => "/api/organisation/division/delete",
  GET_COST_CENTER: () => "/api/organisation/cost-center/get",
  ADD_COST_CENTER: () => "/api/organisation/cost-center/add",
  GET_COST_CENTER_BY_ID: () => "/api/organisation/cost-center/getById",
  EDIT_COST_CENTER: () => "/api/organisation/cost-center/updateById",
  DELETE_COST_CENTER: () => "/api/organisation/cost-center/delete",
  GET_SUB_DEPARTMENT: () => "/api/organisation/sub-department/get",
  ADD_SUB_DEPARTMENT: () => "/api/organisation/sub-department/add",
  GET_SUB_DEPARTMENT_BY_ID: () => "/api/organisation/sub-department/getById",
  EDIT_SUB_DEPARTMENT: () => "/api/organisation/sub-department/updateById",
  DELETE_SUB_DEPARTMENT: () => "/api/organisation/sub-department/delete",
  GET_PAY_GRADE: () => "/api/organisation/pay-grade/get",
  ADD_PAY_GRADE: () => "/api/organisation/pay-grade/add",
  GET_PAY_GRADE_BY_ID: () => "/api/organisation/pay-grade/getById",
  EDIT_PAY_GRADE: () => "/api/organisation/pay-grade/updateById",
  DELETE_PAY_GRADE: () => "/api/organisation/pay-grade/delete",
  GET_BAND: () => "/api/organisation/band/get",
  ADD_BAND: () => "/api/organisation/band/add",
  GET_BAND_BY_ID: () => "/api/organisation/band/getById",
  EDIT_BAND: () => "/api/organisation/band/updateById",
  DELETE_BAND: () => "/api/organisation/band/delete",
  GET_LOCATION: () => "/api/organisation/location/get",
  ADD_LOCATION: () => "/api/organisation/location/add",
  GET_LOCATION_BY_ID: () => "/api/organisation/location/getById",
  EDIT_LOCATION: () => "/api/organisation/location/updateById",
  DELETE_LOCATION: () => "/api/organisation/location/delete",
  GET_LEGAL_ENTITY: () => "/api/organisation/legal-entity/get",
  ADD_LEGAL_ENTITY: () => "/api/organisation/legal-entity/add",
  GET_LEGAL_ENTITY_BY_ID: () => "/api/organisation/legal-entity/getById",
  EDIT_LEGAL_ENTITY: () => "/api/organisation/legal-entity/updateById",
  DELETE_LEGAL_ENTITY: () => "/api/organisation/legal-entity/delete",
};

//Department
export const getDepartment = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_DEPARTMENT(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};

export const createDepartment = async (inputData) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_DEPARTMENT(),
    inputData
  );
  return commonThenResult(response);
};

export const updateDepartment = async (inputData) => {
  const response = await baseAPIFactory.post(END_POINTS.EDIT_DEPARTMENT(), {
    ...inputData,
  });
  return commonThenResult(response);
};

export const deleteDepartment = async (inputData) => {
  const response = await baseAPIFactory.delete(END_POINTS.DELETE_DEPARTMENT(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};

//Business Unit
export const getBusinessUnit = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_BUSINESS_UNIT(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};

export const createBusinessUnit = async (inputData) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_BUSINESS_UNIT(),
    inputData
  );
  return commonThenResult(response);
};

export const updateBusinessUnit = async (inputData) => {
  const response = await baseAPIFactory.post(END_POINTS.EDIT_BUSINESS_UNIT(), {
    ...inputData,
  });
  return commonThenResult(response);
};

export const deleteBusinessUnit = async (inputData) => {
  const response = await baseAPIFactory.delete(
    END_POINTS.DELETE_BUSINESS_UNIT(),
    {
      params: {
        ...inputData,
      },
    }
  );
  return commonThenResult(response);
};

//Division
export const getDivision = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_DIVISION(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};

export const createDivision = async (inputData) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_DIVISION(),
    inputData
  );
  return commonThenResult(response);
};

export const updateDivision = async (inputData) => {
  const response = await baseAPIFactory.post(END_POINTS.EDIT_DIVISION(), {
    ...inputData,
  });
  return commonThenResult(response);
};

export const deleteDivision = async (inputData) => {
  const response = await baseAPIFactory.delete(END_POINTS.DELETE_DIVISION(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};

//Legal Entity
export const getLegalEntity = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_LEGAL_ENTITY(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};
export const getLegalEntityById = async (inputData) => {
  const response = await baseAPIFactory.get(
    END_POINTS.GET_LEGAL_ENTITY_BY_ID(),
    {
      params: {
        ...inputData,
      },
    }
  );
  return commonThenResult(response);
};

export const createLegalEntity = async (inputData) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_LEGAL_ENTITY(),
    inputData
  );
  return commonThenResult(response);
};

export const updateLegalEntity = async (inputData) => {
  const response = await baseAPIFactory.post(END_POINTS.EDIT_LEGAL_ENTITY(), {
    ...inputData,
  });
  return commonThenResult(response);
};

export const deleteLegalEntity = async (inputData) => {
  const response = await baseAPIFactory.delete(
    END_POINTS.DELETE_LEGAL_ENTITY(),
    {
      params: {
        ...inputData,
      },
    }
  );
  return commonThenResult(response);
};

//Cost Center
export const getCostCenter = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_COST_CENTER(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};

export const createCostCenter = async (inputData) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_COST_CENTER(),
    inputData
  );
  return commonThenResult(response);
};

export const updateCostCenter = async (inputData) => {
  const response = await baseAPIFactory.post(END_POINTS.EDIT_COST_CENTER(), {
    ...inputData,
  });
  return commonThenResult(response);
};

export const deleteCostCenter = async (inputData) => {
  const response = await baseAPIFactory.delete(
    END_POINTS.DELETE_COST_CENTER(),
    {
      params: {
        ...inputData,
      },
    }
  );
  return commonThenResult(response);
};

//Sub Department
export const getSubDepartment = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_SUB_DEPARTMENT(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};

export const createSubDepartment = async (inputData) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_SUB_DEPARTMENT(),
    inputData
  );
  return commonThenResult(response);
};

export const updateSubDepartment = async (inputData) => {
  const response = await baseAPIFactory.post(END_POINTS.EDIT_SUB_DEPARTMENT(), {
    ...inputData,
  });
  return commonThenResult(response);
};

export const deleteSubDepartment = async (inputData) => {
  const response = await baseAPIFactory.delete(
    END_POINTS.DELETE_SUB_DEPARTMENT(),
    {
      params: {
        ...inputData,
      },
    }
  );
  return commonThenResult(response);
};

//Location
export const getLocation = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_LOCATION(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};
export const getLocationById = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_LOCATION_BY_ID(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};

export const createLocation = async (inputData) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_LOCATION(),
    inputData
  );
  return commonThenResult(response);
};

export const updateLocation = async (inputData) => {
  const response = await baseAPIFactory.post(END_POINTS.EDIT_LOCATION(), {
    ...inputData,
  });
  return commonThenResult(response);
};

export const deleteLocation = async (inputData) => {
  const response = await baseAPIFactory.delete(END_POINTS.DELETE_LOCATION(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};

//Band

export const getBand = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_BAND(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};

export const createBand = async (inputData) => {
  const response = await baseAPIFactory.post(END_POINTS.ADD_BAND(), inputData);
  return commonThenResult(response);
};

export const updateBand = async (inputData) => {
  const response = await baseAPIFactory.post(END_POINTS.EDIT_BAND(), {
    ...inputData,
  });
  return commonThenResult(response);
};

export const deleteBand = async (inputData) => {
  const response = await baseAPIFactory.delete(END_POINTS.DELETE_BAND(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};

//Pay Grade
export const getPayGrade = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_PAY_GRADE(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};

export const createPayGrade = async (inputData) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_PAY_GRADE(),
    inputData
  );
  return commonThenResult(response);
};

export const updatePayGrade = async (inputData) => {
  const response = await baseAPIFactory.post(END_POINTS.EDIT_PAY_GRADE(), {
    ...inputData,
  });
  return commonThenResult(response);
};

export const deletePayGrade = async (inputData) => {
  const response = await baseAPIFactory.delete(END_POINTS.DELETE_PAY_GRADE(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};
