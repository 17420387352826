export const LEGAL_ENTITY_INITIAL_DATA = {
  name: "",
  registeredName: "",
  dateOfIncorporation: "",
  cinNum: "",
  panNum: "",
  businessType: "",
  bankDetail: { bankName: "", branchName: "", ifscCode: "", accountNumber: "" },
  address: {
    telephone: "",
    addressLine1: "",
    addressLine2: "",
    countryId: 0,
    stateId: "",
    cityId: "",
    pincode: "",
    currency: "",
    website: "",
    financialYear: "",
  },
  natureOfBusiness: "",

  phoneNumber: "",
};
export const BUSINESS_TYPE = [
  "Private Limited Company",
  "Public Sector",
  "Sole Proprietorship",
  "Partnership",
  "Limited Liability Partnership (LLP)",
  "Public Limited",
  "NPO",
  "NGO",
];
export const NATURE_OF_BUSINESS = [
  "Agriculture and Natural Resources",
  "Construction and Real Estate",
  "Manufacturing and Production",
  "Wholesale and Retail Trade",
  "Transportation and Logistics",
  "Information Technology and Software",
  "Finance and Insurance",
  "Professional and Business Services",
  "Education and Training",
  "Healthcare and Social Services",
  "Arts, Entertainment, and Recreation",
  "Hospitality and Food Services",
  "Public Services and Government",
  "Non-Profit and NGO",
  "Food & Toys",
];
export const CURRENCY_DATA = [
  {
    id: 1,
    countryId: 1,
    name: "INR",
  },
];
export const FINANCIAL_YEAR_DATA = [
  {
    id: 1,
    countryId: 1,
    name: "April - March",
  },
];
