import baseAPIFactory, { commonThenResult } from "../../../api/mtrack";

export const END_POINTS = {
  GET_ALL_OVERTIME_POLICY: () => "/api/overtimePolicy/overtime-policy",
  ADD_OVERTIME_POLICY: () => "/api/overtimePolicy/overtime-policy/add",
  UPDATE_OVERTIME_POLICY: () => "/api/overtimePolicy/overtime-policy/update",
  ADD_OVERTIME_POLICY_METHODS: () =>
    "/api/overtimePolicy/overtime-policy-rule/add",
  UPDATE_OVERTIME_POLICY_METHODS: () =>
    "/api/overtimePolicy/overtime-policy-rule/update",
  GET_APPROVAL_TASKS: () => "/api/overtimePolicy/request/all",
  APPROVE_OVERTIME_REQUEST: () => "/api/overtimePolicy/request/update",
  GET_BY_ID_OVERTIME_POLICY: () => "/api/overtimePolicy/overtime-policy",
  GET_ALL_OVERTIME_POLICY_TYPE: () =>
    "/api/overtimePolicy/overtime-policy-type/",
  GET_REQUEST_APPROVAL_BY_ID: () =>
    "/api/overtimePolicy/overtime-request-approval/",
  GET_ALL_OVERTIME_POLICY_ALLOCATION: () =>
    "api/overtimePolicy/overtime-allocation/all",
  CREATE_OR_UPDATE_OVERTIME_POLICY_ALLOCATION: () => "api/overtimePolicy/overtime-allocation/add",

  ADD_OVERTIME_POLICY_TYPE: () => "/api/overtimePolicy/overtime-policy-type/add",
  UPDATE_OVERTIME_POLICY_TYPE : () => "api/overtimePolicy/overtime-policy-type/update",

  ADD_REQUEST_APPROVAL: () => "/api/overtimePolicy/overtime-request-approval/add",
  UPDATE_OVERTIME_POLICY_REQUEST_APPROVAL: () => "/api/overtimePolicy/overtime-request-approval/update"
};

export const getAllApprovalTasks = async ({
  companyId,
  requestTypeId,
  employeeId,
  employeeName,
  departmentName,
  startDate,
  endDate,
  overtimeType,
}) => {
  const result = await baseAPIFactory.get(END_POINTS.GET_APPROVAL_TASKS(), {
    params: {
      companyId,
      requestTypeId,
      employeeName,
      departmentName,
      startDate,
      endDate,
      overtimeType,
    },
  });
  return commonThenResult(result);
};

export const approveOvertimeRequest = async (
  employeeId,
  companyId,
  requestTypeId,
  inputData
) => {
  const response = await baseAPIFactory.patch(
    END_POINTS.APPROVE_OVERTIME_REQUEST(),
    inputData
  );
  return commonThenResult(response);
};

export const getAllOvertimePolicy = async ({
  companyId,
  policyName,
  overtimePolicyId,
  page,
  limit
}) => {
  const response = await baseAPIFactory.get(
    END_POINTS.GET_ALL_OVERTIME_POLICY(),
    {
      params: {
        companyId,
        policyName,
        overtimePolicyId,
        page,
        limit,
      },
    }
  );
  return commonThenResult(response);
};

export const getRequestApproval = async (overtimePolicyId) => {
  console.log("reached")
  const response = await baseAPIFactory.get(
    END_POINTS.GET_REQUEST_APPROVAL_BY_ID(),
    {
      params: {
        overtimePolicyId,
      },
    }
  );

  return commonThenResult(response.data);
};

export const getAllOvertimePolicyType = async (overtimePolicyId) => {
  const response = await baseAPIFactory.get(
    END_POINTS.GET_ALL_OVERTIME_POLICY_TYPE(),
    {
      params: {
        overtimePolicyId,
      },
    }
  );
  return commonThenResult(response.data);
};

export const getResponseApproval = async (overtimePolicyId) => {
  const response = await baseAPIFactory.get(
    END_POINTS.GET_ALL_OVERTIME_POLICY_TYPE(),
    {
      params: {
        overtimePolicyId,
      },
    }
  );
  return commonThenResult(response.data);
};

export const getByIdOvertimePolicy = async (overtimePolicyId) => {
  const response = await baseAPIFactory.get(
    `${END_POINTS.GET_BY_ID_OVERTIME_POLICY()}/${overtimePolicyId}`,
    {}
  );

  return commonThenResult(response.data);
};

export const createOvertimePolicy = async (data) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_OVERTIME_POLICY(),
    data
  );
  return commonThenResult(response.data);
};

export const updateOvertimePolicy = async (inputData) => {
  const response = await baseAPIFactory.patch(
    END_POINTS.UPDATE_OVERTIME_POLICY(),
    { ...inputData }
  );
  return commonThenResult(response);
};

export const createOvertimePolicyMethods = async (data) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_OVERTIME_POLICY_METHODS(),
    data
  );

  return commonThenResult(response.data);
};

export const updateOvertimePolicyMethods = async (inputData) => {
  const response = await baseAPIFactory.patch(
    END_POINTS.UPDATE_OVERTIME_POLICY_METHODS(),
    { ...inputData }
  );
  return commonThenResult(response);
};

export const updateOvertimePolicyRequestApproval = async (inputData) => {
  const response = await baseAPIFactory.patch(
    END_POINTS.UPDATE_OVERTIME_POLICY_REQUEST_APPROVAL(),
    { ...inputData }
  );
  return commonThenResult(response);
};

export const editOverTimePolicy = async (data) => {
  console.log(data, "in edit api");
};



export const getAllOvertimePolicyAllocation = async (
  companyId,
  overtimePolicyId,
 
) => {
  const response = await baseAPIFactory.get(
    END_POINTS.GET_ALL_OVERTIME_POLICY_ALLOCATION(),
    {
      params: {
        companyId,
        overtimePolicyId,
       
      },
    }
  );
  return commonThenResult(response);
};


export const createOrUpdateOvertimePolicyAllocation = async (data) => {
  const response = await baseAPIFactory.post(
    END_POINTS.CREATE_OR_UPDATE_OVERTIME_POLICY_ALLOCATION(),
    data
  );
  return commonThenResult(response);
};


export const createOvertimePolicyType = async (data) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_OVERTIME_POLICY_TYPE(),
    data
  );
  return commonThenResult(response.data);
};

export const updateOvertimePolicyType = async (data) => {
  const response = await baseAPIFactory.patch(
    END_POINTS.UPDATE_OVERTIME_POLICY_TYPE(),
    {...data}
  );
  return commonThenResult(response.data);
};

export const createOvertimeRequestApproval = async (data) => {
  const response = await baseAPIFactory.post(
    END_POINTS.ADD_REQUEST_APPROVAL(),
    data
  );
  return commonThenResult(response.data);
};