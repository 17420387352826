import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "@windmill/react-ui";

function RequestTypeSelect({ setOvertimeType }) {
  const { t } = useTranslation();

  return (
    <div className="w-54  mr-2">
      <Select
        className=" mb-6 border"
        onChange={(e) => {
          setOvertimeType(e.target.value);
        }}
      >
        <option hidden>{t("Select Request Type")}</option>{" "}
        {/* Placeholder option */}
        {/* Static options for "Future Dated" and "Past Dated" */}
        <option value="Future Dated">{t("Future Dated")}</option>
        <option value="Past Dated">{t("Past Dated")}</option>
      </Select>
    </div>
  );
}

export default RequestTypeSelect;
