export default {
  button: {
    primary: {
      base: 'text-white bg-blue-800 border border-transparent',
      active:
        'transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-blue-500 duration-300 focus:ring focus:ring-indigo-300',
      disabled: 'opacity-50 cursor-not-allowed',
    },
  },
};
