import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import snakeCase from 'lodash/snakeCase';
import clsx from 'clsx';

const Tab = ({ items, activeTab }) => {
  const [cActiveTab, setCActiveTab] = useState(activeTab);

  useEffect(() => {
    setCActiveTab(activeTab);
  }, [activeTab]);

  const { titles, component, id } = useMemo(() => {
    const activeComponent = items[cActiveTab] || null;
    const titles = Array.isArray(items)
      ? items.map((item, id) => ({
          id,
          title: item.title,
        }))
      : [];

    if (activeComponent !== null) {
      const { title } = activeComponent;
      return {
        titles,
        component: activeComponent.component,
        id: snakeCase(title),
      };
    }

    return {
      titles,
      id: null,
      component: null,
    };
  }, [items, cActiveTab]);

  const handleTabSwitch = (id) => (e) => {
    e.preventDefault();
    setCActiveTab(id);
  };

  return (
    <>
      <div className="text-sm font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap pl-4 pb-1 pt-2 -mb-px">
          {titles.map((title) => (
            <li className="mr-2" key={title.id}>
              <button
                type="button"
                onClick={handleTabSwitch(title.id)}
                aria-selected={cActiveTab === title.id}
                className={clsx(
                  'inline-block text-gray-500/90 hover:border-gray-500 px-3 py-4',
                  cActiveTab === title.id && 'text-blue-600 border-b-2 border-blue-500'
                )}
              >
                {title.title}
              </button>
            </li>
          ))}
        </ul>

        <div
          className="bg-gray-50 rounded-lg dark:bg-gray-800 mt-5"
          id={id}
          role="tabpanel"
          aria-labelledby={`${id}-tab`}
        >
          {component ? React.createElement(component, { activeTab: cActiveTab }) : <p>No content available.</p>}
        </div>
      </div>
    </>
  );
};

Tab.defaultProps = {
  activeTab: 0,
};

Tab.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([
        PropTypes.func, // Functional component
        PropTypes.node, // JSX nodes
        PropTypes.element, // React elements
        PropTypes.string, // String content
      ]).isRequired,
    })
  ).isRequired,
  activeTab: PropTypes.number,
};

export default Tab;
