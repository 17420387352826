import * as Yup from 'yup';

export const vendorSchema = Yup.object({
  vendorName: Yup.string().required('Vendor Name is Required'),
  // cinNum: Yup.string().required('CIN Number is Required'),
  vendorCode: Yup.string().required('Vendor Code is Required'),
  legalStatus: Yup.string().required('Select Legal Status'),
  natureOfBusiness: Yup.string().required('Please Enter Nature of Business'),
  email: Yup.string().lowercase().email().required('Email is Required'),
  panNum: Yup.string()
    .required('PAN Number is Required')
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Enter a valid PAN')
    .min(10, 'Enter a valid PAN')
    .max(10, 'Enter a valid PAN'),
  // linNum: Yup.string().required('LIN Number is Required'),
});

export const vendorFinance = Yup.object({
  accountHolderName: Yup.string().required('Account Holder Name is Required'),
  accountNumber: Yup.string().required('Account Number is Required'),
  ifscCode: Yup.string().required('IFSC Code is Required'),
  bankName: Yup.string().required('Enter Bank Name'),
  accountType: Yup.string().required('Select Bank Account Type'),
  branchName: Yup.string().required('Enter Branch Name'),
  // swiftCode: Yup.string().required('Swift Code is Required'),
});

export const vendorAddress = Yup.object({
  addressLine1: Yup.string().required('Address Line 1 is Required'),
  addressLine2: Yup.string().required('Address Line 2 is Required'),
  stateId: Yup.number().required('Select State'),
  cityId: Yup.number().required('Select City'),
  pincode: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits')
    .required('Pincode is Required'),
  telephone: Yup.string()
    .matches(/^[0-9]+$/, 'Please Enter Valid telephone')
    .min(8, 'Please Enter Valid telephone')
    .max(11, 'Please Enter Valid telephone')
    .required('Telephone Number is Required'),
  gstNum: Yup.string()
    .required('Please enter a GST Number')
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      'Enter Valid GST'
    ),
  nameContact: Yup.string().required('Name of Contact is Required'),
  mobileContact: Yup.string()
    .required()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits'),
  designationContact: Yup.string().required('Contact Designation is Required'),
  emailContact: Yup.string()
    .lowercase()
    .email()
    .required('Contact Email is Required'),
});



export const vendorRegistrationSchema = Yup.object({
  businessName: Yup.string().required('Business Name is Required'),

  legalStatus: Yup.string().required('Select Legal Status'),
  natureOfBusiness: Yup.string().required('Please Enter Nature of Business'),
  email: Yup.string().lowercase().email().required('Email is Required'),
  panNum: Yup.string()
    .required('PAN Number is Required')
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Enter a valid PAN')
    .min(10, 'Enter a valid PAN')
    .max(10, 'Enter a valid PAN'),
  // linNum: Yup.string().required('LIN Number is Required'),
});
