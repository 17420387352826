import React from 'react';
import { Label } from '@windmill/react-ui';
import { useTranslation } from 'react-i18next';

function Card(props) {
  const { t } = useTranslation();
  return (
    <div
      className={`relative px-8 py-2 my-6 ${props.className}  bg-white rounded-lg shadow-md dark:bg-gray-800`}
    >
      <Label className="px-4 py-3 font-semibold text-lg text-gray-800 dark:text-gray-300">
        {t(props.heading)}
      </Label>
      {props.children}
    </div>
  );
}

export default Card;
