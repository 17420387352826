import React, { useState } from "react";
import { TECollapse } from "tw-elements-react";

const Accordion = ({ title, content, elementKey }) => {
  const [activeElement, setActiveElement] = useState("");

  const handleClick = () => {
    setActiveElement((prev) => (prev === elementKey ? "" : elementKey));
  };

  return (
    <>
      <div id="accordion" className="mt-4">
        <div className="rounded-lg border border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
          <h2 className="mb-0" id={`heading${elementKey}`}>
            <button
              className={`${
                activeElement === elementKey &&
                "text-primary [box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:!text-primary-400 dark:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
              } group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white`}
              type="button"
              onClick={handleClick}
              aria-expanded={activeElement === elementKey}
              aria-controls={`collapse${elementKey}`}
            >
              <span className="text-lg dark:text-gray-300 font-semibold">
                {title}
              </span>
              <span
                className={`${
                  activeElement === elementKey
                    ? "rotate-[-180deg] -mr-1"
                    : "rotate-0 fill-[#212529]  dark:fill-white"
                } ml-auto h-5 w-5 shrink-0 fill-[#336dec] transition-transform duration-200 ease-in-out motion-reduce:transition-none dark:fill-blue-300`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </button>
          </h2>
          <TECollapse
            show={activeElement === elementKey}
            className="!mt-0 !rounded-b-none !shadow-none"
          >
            <div className="px-5 py-0">{content}</div>
          </TECollapse>
        </div>
      </div>
    </>
  );
};

export default Accordion;
