import baseAPIFactory, { commonThenResult } from './mtrack';

export const END_POINTS = {
  GET_DESIGNATION_LIST: () => '/api/company/designation',
  ADD_DESIGNATION: () => '/api/company/designation/add',
  SEARCH_DESIGNATION: () => '/api/company/designation/search',
  EDIT_DESIGNATION: () => '/api/company/designation/edit',
  DELETE_DESIGNATION: () => '/api/company/designation/delete',
};

export const listDesignation = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_DESIGNATION_LIST(), {
    params: {
      entityId: inputData.companyId,
      status: inputData.status,
    },
  });

  return commonThenResult(response);
};

export const searchDesignation = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.SEARCH_DESIGNATION(), {
    params: {
      entityId: inputData.companyId,
      status: inputData.status,

      // term: inputData.term,
    },
  });

  return commonThenResult(response);
};

export const addDesignation = (inputData) =>
  baseAPIFactory.post(END_POINTS.ADD_DESIGNATION(), inputData);

export const editDesignation = (inputData) => {
  return baseAPIFactory.patch(END_POINTS.EDIT_DESIGNATION(), inputData);
};

export const deleteDesignation = (inputData) => {
  console.log(inputData);

  return baseAPIFactory.patch(END_POINTS.DELETE_DESIGNATION(), inputData);
};
