import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  Input,
  Button,
} from "@windmill/react-ui";
import { useTranslation } from "react-i18next";
import { approveOvertimeRequest } from "../api/overtimeRequests";
import { CheckIcon } from "@heroicons/react/solid";
import OvertimeApprovalModal from "./OvertimeApprovalModal";
import Swal from "sweetalert2";
import Spinner from "../../../components/UI Components/Spinner";

const OvertimeApprovalTable = ({
  approvalTaskData,
  refetchApprovalTasks,
  loading,
}) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [taskToApprove, setTaskToApprove] = useState(null);
  const [status, setStatus] = useState("");
  const [reasons, setReasons] = useState("");
  const [overtimeData, setOvertimeData] = useState([]);

  console.log(approvalTaskData);
  
  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    setSelectedRows(checked ? approvalTaskData.map((task) => task.id) : []);
  };

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const openApproveModal = (task) => {
    if (!task) return;
    setTaskToApprove(task);
    setIsModalOpen(true);
    setStatus(task.status || "");
    setReasons("");

    const initialOvertimeData = task.requestOvertime.overtimeDetails.map(
      (detail) => ({
        date: detail.date,
        overtimeHours: convertMinutesToTime(detail.overtimeMin),
        haltingHours: "00:00",
      })
    );

    setOvertimeData(initialOvertimeData);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTaskToApprove(null);
    setStatus("");
    setReasons("");
    setOvertimeData([]);
  };

  
  const handleApprovalAction = async (actionType) => {
    const tasksToProcess = taskToApprove
      ? [taskToApprove]
      : approvalTaskData.filter((task) => selectedRows.includes(task.id));

    if (!tasksToProcess.length) return;

    try {
      setIsLoading(true);

      for (const task of tasksToProcess) {
        const inputData = {
          tasks: [
            {
              taskId: task.id,
              employeeId: task.employeeDetailsMap?.employeeId || 1,
              userRoleId: task.employeeDetailsMap?.userRoleId || 1,
              requestTypeId: task.requestTypeId,
              companyId: task.companyId,
              action: actionType,
              reasons: actionType === "APPROVED" ? reasons : "",
              noteByEmployee: task.noteByEmployee,
              // For bulk action, keep existing overtime details
              overtimeDetails: taskToApprove
                ? task.id === taskToApprove.id
                  ? overtimeData.map((item) => ({
                      date: item.date,
                      overtimeMin: convertTimeToMinutes(item.overtimeHours),
                    }))
                  : task.requestOvertime.overtimeDetails
                : task.requestOvertime.overtimeDetails,
              waitingOrHaultingDetails: taskToApprove
                ? task.id === taskToApprove.id
                  ? overtimeData.map((item) => ({
                      date: item.date,
                      waitingOrHaultingMin: convertTimeToMinutes(
                        item.haltingHours
                      ),
                    }))
                  : task.requestOvertime.waitingOrHaultingDetails || []
                : task.requestOvertime.waitingOrHaultingDetails || [],
            },
          ],
        };

        await approveOvertimeRequest(
          task.employeeDetailsMap.userId,
          task.companyId,
          task.requestTypeId,
          inputData
        );
      }

      // Use SweetAlert to show a success message
      Swal.fire({
        title: "Success!",
        text: `Tasks ${actionType} successfully.`,
        icon: "success",
        confirmButtonText: "OK",
      });

      closeModal();
      setSelectedRows([]);
      refetchApprovalTasks();
    } catch (error) {
      console.error("Error approving/rejecting tasks:", error);
      // Use SweetAlert to show an error message
      Swal.fire({
        title: "Error!",
        text: "There was an error processing your request. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOvertimeChange = (index, value) => {
    setOvertimeData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index].overtimeHours = value;
      return updatedData;
    });
  };

  const handleHaltingChange = (index, value) => {
    setOvertimeData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index].haltingHours = value;
      return updatedData;
    });
  };

  return (
    <div>
      {/* Actions */}
      <div className="flex justify-end">
        <div className="flex  space-x-2 mb-4 mr-8">
          <Button
            onClick={() => handleApprovalAction("REJECTED")}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "REJECT"}
          </Button>
          <Button
            onClick={() => handleApprovalAction("APPROVED")}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "APPROVE"}
          </Button>
        </div>
      </div>

      {/* Table */}
      <TableContainer className="border-2 w-[260px] md:w-[650px] lg:w-[1000px] overflow-x-auto overflow-y-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>
                <Input
                  className="border-2 border-gray-800 rounded cursor-pointer"
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </TableCell>
              <TableCell>{t("EMPLOYEE_NAME")}</TableCell>
              <TableCell>{t("EMPLOYEE_CODE")}</TableCell>
              <TableCell>{t("LEGAL_ENTITY")}</TableCell>
              <TableCell>{t("BUSINESS_UNIT")}</TableCell>
              <TableCell>{t("DEPARTMENT")}</TableCell>
              <TableCell>{t("SUB_DEPARTMENT")}</TableCell>
              <TableCell>{t("LOCATION")}</TableCell>
              <TableCell>{t("OVERTIME_DATE")}</TableCell>
              <TableCell>{t("REQUEST_TYPE")}</TableCell>
              <TableCell>{t("TOTAL_OVERTIME")}</TableCell>
              <TableCell>{t("NOTE")}</TableCell>
              <TableCell>{t("STATUS")}</TableCell>
              <TableCell>{t("REQUESTED_BY")}</TableCell>
              <TableCell>{t("LAST_ACTION")}</TableCell>
              <TableCell>{t("ACTION")}</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={14} className="text-center">
                  <Spinner />
                </TableCell>
              </TableRow>
            ) : approvalTaskData.length > 0 ? (
              approvalTaskData.map((task) => {
                const totalMinutes =
                  task.requestOvertime?.overtimeDetails.reduce(
                    (acc, detail) => acc + detail.overtimeMin,
                    0
                  ) || 0;
                const totalOvertimeHours = convertMinutesToTime(totalMinutes);

                return (
                  <TableRow key={task.id}>
                    <TableCell>
                      <Input
                        className="border-2 border-gray-800 rounded cursor-pointer"
                        type="checkbox"
                        checked={selectedRows.includes(task.id)}
                        onChange={() => handleCheckboxChange(task.id)}
                      />
                    </TableCell>
                    <TableCell>
                      {task.employeeDetailsMap?.employeeName || ""}
                    </TableCell>
                    <TableCell>
                      {task.employeeDetailsMap?.employeeCode || "N/A"}
                    </TableCell>
                    <TableCell>{task.entityDetail?.name || "N/A"}</TableCell>
                    <TableCell>
                      {task.employeeDetailsMap?.businessUnit || "N/A"}
                    </TableCell>
                    <TableCell>
                      {task.employeeDetailsMap?.department || "N/A"}
                    </TableCell>
                    <TableCell>
                      {task.employeeDetailsMap?.subDepartment || "N/A"}
                    </TableCell>
                    <TableCell>{task.entityLocations?.name || "N/A"}</TableCell>
                    <TableCell>
                      {task.requestOvertime.overtimeDetails.map((detail) => (
                        <div key={detail.date}>
                          {new Date(detail.date).toLocaleDateString()} (
                          {convertMinutesToTime(detail.overtimeMin)})
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>{task.requestOvertime.type || "N/A"}</TableCell>
                    <TableCell>{totalOvertimeHours}</TableCell>
                    <TableCell>
                      {task.requestOvertime?.noteByEmployee || "N/A"}
                    </TableCell>
                    <TableCell>{task.status || "N/A"}</TableCell>
                    <TableCell>
                      {task.employeeDetailsMap?.employeeName || ""}
                    </TableCell>
                    <TableCell>{task.currentApproverName || "N/A"}</TableCell>

                    {/* Action Column */}
                    <TableCell>
                      <div className="flex">
                        <CheckIcon
                          className="w-5 h-5 text-green-500 cursor-pointer"
                          onClick={() => openApproveModal(task)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={14} className="text-center">
                  {t("No approval tasks found.")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal */}
      <OvertimeApprovalModal
        isOpen={isModalOpen}
        onClose={closeModal}
        taskToApprove={taskToApprove}
        status={status}
        setStatus={setStatus}
        reasons={reasons}
        setReasons={setReasons}
        overtimeData={overtimeData}
        handleOvertimeChange={handleOvertimeChange}
        handleHaltingChange={handleHaltingChange}
        handleApprovalAction={handleApprovalAction}
      />
    </div>
  );
};

export default OvertimeApprovalTable;

// Helper function to convert minutes to time format
const convertMinutesToTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
};

// Helper function to convert time format (HH:MM) back to minutes
const convertTimeToMinutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};
