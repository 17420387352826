export const VENDOR_EMPLPANELMENT_STATUS = {
  PENDING: 'pending',
  ACTIVE: 'active',
  INACTIVE: 'inActive',
  ON_HOLD: 'onHold',
  TEMINATED: 'terminated',
  CANCELLED: 'cancelled',
};

export const nameParser = (name) => {
  const nameArray = name.split(' ');
  let firstName = '';
  let lastName = '';

  if (nameArray.length === 1) {
    // If the full name contains only one word, treat it as the first name
    firstName = nameArray[0];

    return { firstName };
  } else if (nameArray.length > 1) {
    // If the full name contains more than one word, extract the first and last names
    lastName = nameArray[nameArray.length - 1];
    firstName = nameArray.slice(0, nameArray.length - 1).join(' ');

    return { firstName, lastName };
  }
};
