import baseAPIFactory, { commonThenResult } from "../../../api/mtrack";

export const END_POINTS = {
  ADD_EMPLOYEE: () => "/api/employee/employee-basic/add",
  ADD_EMPLOYEE_ADDRESS: () => "/api/employee/employee-address/add",
  ADD_EMPLOYEE_DOCUMENTS: () => "/api/employee/employee-document/add",
  ADD_EMPLOYEE_FINANCE: () => "/api/employee/employee-finance/add",
  // RESIGNATION
  GET_EMPLOYEE_RESIGNATION_LIST: () =>
    "/api/employee/employee-resignation/list",
  UPDATE_EMPLOYEE_RESIGNATION_STATUS: () =>
    "/api/employee/employee-resignation/action",
  // terminate
  GET_EMPLOYEE_BY_CODE: () => "/api/employee/employee-basic/employee",
  TERMINATE_EMPLOYEE_BY_AUTH: () =>
    "/api/employee/employee-termination/terminate",
  GET_TERMINATE_EMPLOYEE_LIST: () => "/api/employee/employee-termination/list",

  ASSIGN_EMPLOYEE_JOB: () => "/api/employee/employee-job/assign",

  ADD_EMPLOYEE_LIST: () => "/api/employee/employee-bulk/add",
  GET_EMPLOYEE_LIST: () => "/api/employee/employee-bulk/get",
  BULK_EMPLOYEE_FORMAT: () => "/api/employee/employee-bulk/format",

  GET_EMPLOYEE_REQUIRED_DOCUMENTS: () =>
    "/api/employee/employee-document/required",

  LIST_EMPLOYEE: () => "/api/employee/employee-basic/list",
  SEARCH_EMPLOYEE: () => "/api/employee/employee-basic/search",

  GET_EMPLOYEE: () => "/api/employee/employee-basic/",

  CHECK_EMPLOYEE_EXISTS: () =>
    "/api/employee/employee-basic/check-employee-adhaar",

  EDIT_EMPLOYEE: () => "/api/employee/employee-basic/edit",
  EDIT_EMPLOYEE_ADDRESS: () => "/api/employee/employee-address/edit",
  EDIT_EMPLOYEE_DOCUMENTS: () => "/api/employee/employee-document/edit",
  EDIT_EMPLOYEE_FINANCE: () => "/api/employee/employee-finance/edit",
};

export const getEmployeeResignationList = async (inputData) => {
  const response = await baseAPIFactory.get(
    END_POINTS.GET_EMPLOYEE_RESIGNATION_LIST(),
    {
      params: {
        ...inputData,
      },
    }
  );
  return commonThenResult(response);
};
export const updateEmployeeResignationStatus = async (inputData) => {
  const response = await baseAPIFactory.patch(
    END_POINTS.UPDATE_EMPLOYEE_RESIGNATION_STATUS(),
    inputData
  );
  return commonThenResult(response);
};

export const getEmployeeByCode = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_EMPLOYEE_BY_CODE(), {
    params: {
      ...inputData,
    },
  });
  return commonThenResult(response);
};

export const terminateEmployeeByAuth = async (inputData) => {
  const response = await baseAPIFactory.post(
    END_POINTS.TERMINATE_EMPLOYEE_BY_AUTH(),
    inputData
  );
  return commonThenResult(response);
};

export const getTerminateEmployeeList = async (inputData) => {
  const response = await baseAPIFactory.get(
    END_POINTS.GET_TERMINATE_EMPLOYEE_LIST(),
    {
      params: {
        ...inputData,
      },
    }
  );
  return commonThenResult(response);
};

export const addEmployee = (inputData) =>
  baseAPIFactory.post(END_POINTS.ADD_EMPLOYEE(), inputData);

export const addEmployeeList = (inputData) => {
  return baseAPIFactory.post(END_POINTS.ADD_EMPLOYEE_LIST(), inputData);
  //  ({
  //   headers: { 'Content-Type': 'multipart/form-data' },
  // });
};

export const addEmployeeAddress = (inputData) =>
  baseAPIFactory.post(END_POINTS.ADD_EMPLOYEE_ADDRESS(), inputData);

export const addEmployeeDocuments = (inputData) =>
  baseAPIFactory.post(END_POINTS.ADD_EMPLOYEE_DOCUMENTS(), inputData);

export const addEmployeeFinance = (inputData) =>
  baseAPIFactory.post(END_POINTS.ADD_EMPLOYEE_FINANCE(), inputData);

export const assignJob = (inputData) =>
  baseAPIFactory.post(END_POINTS.ASSIGN_EMPLOYEE_JOB(), inputData);

export const checkIfEmployeeExist = async (inputData) => {
  const { aadhar } = inputData;
  const response = await baseAPIFactory.get(
    END_POINTS.CHECK_EMPLOYEE_EXISTS(),
    {
      params: {
        aadhar,
      },
    }
  );
  return commonThenResult(response);
};

export const listEmployees = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.LIST_EMPLOYEE(), {
    params: {
      limit: inputData.resultsPerPage,
      offset: inputData.page,
      companyId: inputData.companyId,
      status: inputData.status,
    },
  });
  return commonThenResult(response);
};
export const getEmployee = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_EMPLOYEE(), {
    params: {
      employeeId: inputData,
    },
  });
  return commonThenResult(response);
};

export const getEmployeeRequiredDocuments = async (inputData) => {
  const response = await baseAPIFactory.get(
    END_POINTS.GET_EMPLOYEE_REQUIRED_DOCUMENTS(),
    {
      params: {
        companyId: inputData,
      },
    }
  );

  return commonThenResult(response);
};

export const getEmployeeBulkFormat = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.BULK_EMPLOYEE_FORMAT(), {
    params: {
      title: inputData,
    },
  });
  console.log(response);
  return commonThenResult(response);
};
export const getEmployeeBulkData = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_EMPLOYEE_LIST(), {
    params: {
      ...inputData,
    },
    responseType: "blob",
  });
  return commonThenResult(response);
};

// SERACH API

export const searchEmployees = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.SEARCH_EMPLOYEE(), {
    params: {
      limit: inputData.resultsPerPage,
      offset: inputData.page,
      companyId: inputData.companyId,
      nameTerm: inputData.termName,
      vendorCode: inputData.vendorCode,
      employeeCode: inputData.employeeCode,
      status: inputData.status,
    },
  });
  return commonThenResult(response);
};

// EDIT API

export const editEmployee = (inputData) =>
  baseAPIFactory.patch(END_POINTS.EDIT_EMPLOYEE(), {
    params: {
      employeeId: inputData.employeeId,
    },
    data: inputData,
  });

export const editEmployeeAddress = (param, inputData) =>
  baseAPIFactory.patch(END_POINTS.EDIT_EMPLOYEE_ADDRESS(), {
    params: {
      employeeAddressId: param,
    },
    data: inputData,
  });

export const editEmployeeDocument = (param, inputData) =>
  baseAPIFactory.patch(END_POINTS.EDIT_EMPLOYEE_DOCUMENTS(), {
    params: {
      employeeDocumentId: param,
    },
    data: inputData,
  });

export const editEmployeeFinance = ({ employeeBankId, data }) =>
  baseAPIFactory.patch(END_POINTS.EDIT_EMPLOYEE_FINANCE(), {
    params: {
      employeeBankId,
    },
    data,
  });
