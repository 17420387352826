/* eslint-disable */
const LOG_LEVELS = ['error', 'warn', 'info', 'log', 'debug', 'trace'];

const initLogger = ()  => {
  const currentEnvironment = process.env.REACT_APP_SERVER || 'local';
  const isLoggingEnabled = ['local', 'staging'].includes(currentEnvironment);
  const logger = {
    error: () => {},
    warn: () => {},
    info: () => {},
    log: () => {},
    debug: () => {},
    trace: () => {},
  };
  if (isLoggingEnabled) {
    const logLevel = process.env.DEFAULT_LOG_LEVEL || 'log';
    const logLevelIndex = LOG_LEVELS.findIndex((level) => level === logLevel);
    const enabledLogLevels = LOG_LEVELS.slice(0, logLevelIndex + 1);
    for (const m in console) {
      const consoleType = console[m];
      if (typeof consoleType === 'function') {
        if (enabledLogLevels.includes(m)) {
          logger[m] = consoleType.bind(window.console, 'APP-LOG: ');
        }
      }
    }
  }
  return logger;
};

const appLogger = initLogger();
export default appLogger;
