import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Input, Label, Button, Select as UiSelect } from "@windmill/react-ui";
import {
  BUSINESS_TYPE,
  CURRENCY_DATA,
  FINANCIAL_YEAR_DATA,
  LEGAL_ENTITY_INITIAL_DATA,
  NATURE_OF_BUSINESS,
} from "./constant";
import { legalEntitySchema } from "../schema";
import { useTranslation } from "react-i18next";
import { listCities, listStates } from "../../../../api/common";
import { isEmpty, isFunction } from "lodash";
import { Link } from "react-router-dom";

const LegalEntityForm = ({ handleFormSubmit, initialValue, buttons }) => {
  const { t } = useTranslation();

  const isViewMode = !handleFormSubmit;

  const [cities, setCities] = useState([]);
  const [state, setState] = useState([]);
  const countryId = 1;
  const [stopSelection, setStopSelection] = useState(true);
  const [currencyData, setCurrencyData] = useState([]);

  useEffect(() => {
    listStates(countryId).then((data) => setState(data));
  }, [countryId]);

  const handleOnChange = async (event) => {
    setStopSelection(true);
    const city = await listCities(event.target.value);
    setCities(city);
    setStopSelection(false);
  };
  const handleCountryChange = async (event, setFieldValue) => {
    setCurrencyData(
      CURRENCY_DATA.filter((c) => c.countryId === parseInt(event))
    );

    setFieldValue(
      "address.financialYear",
      FINANCIAL_YEAR_DATA.find((c) => c.countryId === parseInt(event))?.name ||
        ""
    );
  };

  const initialValues = {
    ...LEGAL_ENTITY_INITIAL_DATA,
    ...initialValue,
  };
  useEffect(() => {
    if (initialValue && initialValue?.address?.stateId) {
      setStopSelection(true);
      listCities(initialValue?.address?.stateId).then((city) => {
        setCities(city);
        setStopSelection(false);
      });
    }
  }, [initialValue]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={legalEntitySchema}
      onSubmit={handleFormSubmit || (() => {})} // no-op function for view mode
      enableReinitialize={true}
    >
      {({ handleSubmit, setFieldValue, isSubmitting, values }) => (
        <form
          autoComplete="off"
          className="space-y-4 bg-white shadow-xl pb-6"
          onSubmit={isViewMode ? (e) => e.preventDefault() : handleSubmit}
        >
          <div className="mb-10 ml-5">
            <span>{t("Legal Entity Details")}</span>
            <div className="flex flex-row flex-wrap gap-x-8 pt-4 pb-8">
              <Label className=" items-center pb-4">
                <span>{t("ENTITY_NAME")}*</span>

                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    name="name"
                    type="text"
                    placeholder="entity Name "
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>

              <Label className=" items-center pb-4">
                <span>{t("REGISTERED_NAME")}*</span>

                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    name="registeredName"
                    type="text"
                    placeholder="Registered Name"
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="registeredName"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>
              <Label className=" items-center pb-4">
                <span>{t("Country")}*</span>

                <div className="w-96">
                  <Field
                    as={UiSelect}
                    value={values?.address?.countryId}
                    className="border
                   mt-1 mb-3 w-full py-2 block "
                    onChange={(event) => {
                      console.log(event.target.value);

                      setFieldValue("address.countryId", event.target.value);
                      handleCountryChange(event.target.value, setFieldValue);
                    }}
                    name="address.countryId"
                    disabled={isViewMode}
                  >
                    <option value={0}>Select Country</option>
                    <option key={1} value={1}>
                      India
                    </option>
                  </Field>
                  <ErrorMessage
                    name="address.countryId"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>

              <Label className=" items-center pb-4">
                <span>{t("DATE_OF_INCORPORATION")}*</span>

                <div className="w-96">
                  <Field
                    type="date"
                    className="border
                   mt-1 mb-3 w-full py-2 block "
                    name={"dateOfIncorporation"}
                    onChange={(event) => {
                      setFieldValue("dateOfIncorporation", event.target.value);
                    }}
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="dateOfIncorporation"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>
              <Label className=" items-center pb-4">
                <span>{t("COMPANY_INCORPORATION_NUMBER")}*</span>

                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    name="cinNum"
                    type="text"
                    placeholder="cin "
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="cinNum"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>

              <Label className=" items-center pb-4">
                <span>{t("BUSINESS_TYPE")}*</span>

                <div className="w-96">
                  <Field
                    as={UiSelect}
                    value={values?.businessType}
                    className="border
                   mt-1 mb-3 w-full py-2 block "
                    name="businessType"
                    disabled={isViewMode}
                  >
                    <option>Select Business Type</option>
                    {!isEmpty(BUSINESS_TYPE) &&
                      BUSINESS_TYPE.map((bt, index) => {
                        return (
                          <option key={index} value={bt}>
                            {bt}
                          </option>
                        );
                      })}
                  </Field>
                  <ErrorMessage
                    name="businessType"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>
              <Label className=" items-center pb-4">
                <span>{t("NATURE_OF_BUSINESS")}*</span>

                <div className="w-96">
                  <Field
                    as={UiSelect}
                    value={values?.natureOfBusiness}
                    className="border
                   mt-1 mb-3 w-full py-2 block "
                    name="natureOfBusiness"
                    disabled={isViewMode}
                  >
                    <option>Select Nature Business</option>
                    {!isEmpty(NATURE_OF_BUSINESS) &&
                      NATURE_OF_BUSINESS.map((bt, index) => {
                        return (
                          <option key={index} value={bt}>
                            {bt}
                          </option>
                        );
                      })}
                  </Field>
                  <ErrorMessage
                    name="natureOfBusiness"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>
              <Label>
                <span> {t("PAN_NUMBER")} *</span>
                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    type="text"
                    placeholder={t("ENTER_PAN_NUMBER")}
                    name="panNum"
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="panNum"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>

              <Label className=" items-center pb-4">
                <span>{t("Currency")}*</span>

                <div className="w-96">
                  <Field
                    as={UiSelect}
                    value={values?.address?.currency}
                    className="border
                   mt-1 mb-3 w-full py-2 block "
                    name="address.currency"
                    disabled={isViewMode}
                  >
                    <option>Select Currency</option>
                    {!isEmpty(currencyData) &&
                      currencyData.map(({ id, name }) => {
                        return (
                          <option key={id} value={name}>
                            {name}
                          </option>
                        );
                      })}
                  </Field>
                  <ErrorMessage
                    name="address.currency"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>
              <Label className=" items-center pb-4">
                <span>{t("FINANCIAL_YEAR")}*</span>

                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    name="address.financialYear"
                    type="text"
                    placeholder="Financial Year "
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="address.financialYear"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>

              <Label className=" items-center pb-4">
                <span>{t("WEBSITE")}*</span>

                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    name="address.website"
                    type="text"
                    placeholder="WebSite"
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="address.website"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>
              <Label className=" items-center pb-4">
                <span>{t("PHONE_NUMBER")}*</span>

                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    name="address.telephone"
                    type="text"
                    placeholder="Phone Number "
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="address.telephone"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>
            </div>
            <span>{t("ADDRESS")}</span>

            <div className="flex flex-row flex-wrap gap-x-8 pt-4 pb-8">
              <Label className=" items-center pb-4">
                <span>{t("ADDRESS_LINE_1")}*</span>

                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    name="address.addressLine1"
                    type="text"
                    placeholder="address line 1 "
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="address.addressLine1"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>

              <Label className=" items-center pb-4">
                <span>{t("ADDRESS_LINE_2")}*</span>

                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    name="address.addressLine2"
                    type="text"
                    placeholder="address line 2"
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="address.addressLine2"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>

              <Label className=" items-center pb-4">
                <span>{t("STATE")}*</span>

                <div className="w-96">
                  <UiSelect
                    name="address.stateId"
                    value={values?.address?.stateId}
                    onChange={(event) => {
                      setFieldValue("address.stateId", event.target.value);
                      handleOnChange(event);
                    }}
                    disabled={isViewMode}
                    className="border py-2 mt-1 mb-3 w-full block "
                  >
                    <option>Select State</option>
                    {!isEmpty(state) &&
                      state.map(({ id, name }) => {
                        return (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        );
                      })}
                  </UiSelect>
                  <ErrorMessage
                    name="address.stateId"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>

              <Label className=" items-center pb-4">
                <span>{t("CITY")}*</span>

                <div className="w-96">
                  <Field
                    as={UiSelect}
                    value={values?.address?.cityId}
                    className="border
                   mt-1 mb-3 w-full py-2 block "
                    name="address.cityId"
                    disabled={stopSelection || isViewMode}
                  >
                    <option>Select City</option>
                    {!isEmpty(cities) &&
                      cities.map(({ id, name }) => {
                        return (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        );
                      })}
                  </Field>
                  <ErrorMessage
                    name="address.cityId"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>

              <Label className=" items-center pb-4">
                <span>{t("ZIPCODE")}*</span>

                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    name="address.pincode"
                    type="text"
                    placeholder="pincode"
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="address.pincode"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>
              <br />
            </div>
            <span>{t("Bank Details (Optional)")}</span>
            <div className="flex flex-row flex-wrap gap-x-8 pt-4 pb-8">
              <Label className=" items-center pb-4">
                <span>{t("BANK_NAME")}</span>

                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    name="bankDetail.bankName"
                    type="text"
                    placeholder="Bank "
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="bankDetail.bankName"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>

              <Label className=" items-center pb-4">
                <span>{t("BRANCH")}</span>

                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    name="bankDetail.branchName"
                    type="text"
                    placeholder="Branch"
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="bankDetail.branchName"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>
              <Label className=" items-center pb-4">
                <span>{t("IFSC_CODE")}</span>

                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    name="bankDetail.ifscCode"
                    type="text"
                    placeholder="IFSC CODE "
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="bankDetail.ifscCode"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>

              <Label className=" items-center pb-4">
                <span>{t("ACCOUNT_NUMBER")}</span>

                <div className="w-96">
                  <Field
                    as={Input}
                    className="border mt-1 mb-3  block  "
                    name="bankDetail.accountNumber"
                    type="text"
                    placeholder="Account Number"
                    disabled={isViewMode}
                  />
                  <ErrorMessage
                    name="bankDetail.accountNumber"
                    component="div"
                    className="text-red-600"
                  />
                </div>
              </Label>
            </div>
          </div>
          {!buttons && (
            <div className="max-w-full flex justify-center  items-center space-x-8 ">
              <Link to="/app/organisation/legal-entity">
                <Button type="button" className="mr-4" layout="outline">
                  CANCEL
                </Button>
              </Link>

              {!isViewMode && <Button type="submit">Save</Button>}
            </div>
          )}
          {isFunction(buttons) && buttons(isSubmitting)}
        </form>
      )}
    </Formik>
  );
};

export default LegalEntityForm;
