import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  vendorId: null,
  vendorEmpanelmentId: null,
  gstNum: '',
  panNum: '',
};

const vendorFormData = createSlice({
  name: 'vendorFormData',
  initialState,
  reducers: {
    capturevendorFormData(state, action) {
      //   console.log(action);

      state.vendorId = action.payload.vendorId;
      state.vendorEmpanelmentId = action.payload.vendorEmpanelmentId;
      state.gstNum = action.payload.gstNum;
      state.panNum = action.payload.panNum;
    },
  },
});

export const { capturevendorFormData } = vendorFormData.actions;

export default vendorFormData.reducer;
