 import { createSlice } from "@reduxjs/toolkit";


 const penalizationPolicyFormSlice = createSlice({

        name : 'penalizationPolicyForm',

        initialState : {
            violationForPenalizationId : null,
            penalizationPolicyId : null
        },

        reducers : {
            setPenalizationPolicyId: (state , action) => {
                state.penalizationPolicyId = action.payload
                console.log("penalizationPolicyId", state.penalizationPolicyId)
            },

            setViolationForPenalizationId : (state , action) => {
                state.violationForPenalizationId = action.payload
                console.log("setViolationForPenalizationId", state.violationForPenalizationId)
            }
        }
 });

 export const { setPenalizationPolicyId, setViolationForPenalizationId } = penalizationPolicyFormSlice.actions;

export default penalizationPolicyFormSlice.reducer;