import React, { useState } from 'react';
import { Dropdown, DropdownItem, Button } from '@windmill/react-ui';
import { GlobalIcon } from '../../assets/asset';
import i18next from 'i18next';

function DropdownOp() {
  const [isOpen, setIsOpen] = useState(false);
  function toggleDropdown() {
    setIsOpen(!isOpen);
  }
  return (
    <div className="relative">
      <Button
        onClick={toggleDropdown}
        aria-label="Notifications"
        aria-haspopup="true"
      >
        <GlobalIcon className="w-4 h-4 mr-2" aria-hidden="true" />
        Lng
      </Button>
      <Dropdown isOpen={isOpen} onClose={() => {}}>
        <DropdownItem
          className="justify-between"
          onClick={() => i18next.changeLanguage('en')}
        >
          <span>English</span>
        </DropdownItem>
        <DropdownItem onClick={() => i18next.changeLanguage('hi')}>
          <span>हिन्दी</span>
        </DropdownItem>
      </Dropdown>
    </div>
  );
}
export default DropdownOp;
