import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './authenticationSlice';
import companyReducer from './companySlice';
import modalReducer from './modalManageSlice';
import vendorFormReducer from './vendorFormSlice';
import employeeFormReducer from './employeeFormSlice';
import capturePolicyFormReducer from './capturePolicyFormSlice'
import penalizationPolicyFormReducer from './penalizationPolicyFormSlice'
import NavBarReducer from './NavSlice';
import overtimeReducer from './overtimeSlice';
// import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session';

const persistConfig = {
  key: 'root',
  version: 1.01,
  storage: storageSession,
 // withCredentials: false //---> changes required 
};

const rootReducer = combineReducers({
  authentication: authReducer,
  companyData: companyReducer,
  modalManage: modalReducer,
  vendorFormData: vendorFormReducer,
  employeeFormData: employeeFormReducer,
  capturePolicyForm : capturePolicyFormReducer,
  penalizationPolicyForm : penalizationPolicyFormReducer,
  navbar : NavBarReducer,
  overtimeData : overtimeReducer,

});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.REACT_APP_SERVER === 'local',
});

export const persistor = persistStore(store);
