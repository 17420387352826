const Yup = require('yup');

export const nonRequiredDocuments = {
  panNumber: Yup.string()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Enter a valid PAN')
    .min(10, 'Enter a valid PAN')
    .max(10, 'Enter a valid PAN'),
  gstNumber: Yup.string().matches(
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    'Enter Valid GST'
  ),
  uanNumber: Yup.string().min(12, 'Enter Valid UAN').max(12, 'Enter Valid UAN'),
  aadhaarNumber: Yup.string().matches(
    /^\d{4}-\d{4}-\d{4}$/,
    'Enter Valid Aadhar'
  ),
};

export const requiredDocuments = {
  panNumber: Yup.string()
    .required('PAN Number is Required')
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}/, 'Enter a valid PAN')
    .min(10, 'Enter a valid PAN')
    .max(10, 'Enter a valid PAN'),
  gstNumber: Yup.string()
    .required('Please enter a GST Number')
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      'Enter Valid GST'
    ),
  uanNumber: Yup.string().required('Please enter UAN Number').min(12).max(12),
  aadhaarNumber: Yup.string()
    .required('Please enter Aadhaar Number')
    .matches(/^\d{4}-\d{4}-\d{4}$/, 'Enter Valid Aadhar'),
};
