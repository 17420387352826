import { ErrorMessage, Field, Formik } from "formik";
import React from "react";
import { Input, Label, Button } from "@windmill/react-ui";
import { PAT_GRADE_BAND_INITIAL_DATA } from "./constant";
import { DeptDivBUCCSchema } from "../schema";
import { isFunction } from "lodash";

const PayGradeBandForm = ({
  handleFormSubmit,
  initialValue,
  formId,
  entityType,
  buttons,
}) => {
  const entityLabels = {
    band: "BAND",
    payGrade: "Pay Grade",
  };

  const entityLabel = entityLabels[entityType] || "Entity";
  const isViewMode = !handleFormSubmit;
  const initialValues = {
    ...PAT_GRADE_BAND_INITIAL_DATA,
    ...initialValue,
  };
  console.log(initialValues);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DeptDivBUCCSchema}
      onSubmit={handleFormSubmit || (() => {})} // no-op function for view mode
      enableReinitialize={true}
    >
      {({ handleSubmit, setFieldValue, isSubmitting, values }) => (
        <form
          id={formId}
          autoComplete="off"
          className={`space-y-4 ${
            isFunction(buttons) ? "bg-white shadow-xl pb-6" : ""
          } `}
          onSubmit={isViewMode ? (e) => e.preventDefault() : handleSubmit}
        >
          <div className="mb-10 ml-5">
            <div className="grid md:grid-cols-2 grid-cols-1  gap-4 ml-4 mb-6">
              <span className="text-base flex flex-col">
                {entityLabel} Name*
              </span>
              <Label>
                <Field
                  as={Input}
                  type="text"
                  className="mb-3 border border-black"
                  placeholder={`Enter ${entityLabel} Name`}
                  name={`name`}
                  disabled={isViewMode}
                />
                <ErrorMessage
                  name={`name`}
                  component="div"
                  className="text-red-600"
                />
              </Label>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1  gap-4 ml-4 mb-6">
              <span className="text-base flex flex-col">
                {entityLabel} Code*
              </span>
              <Label>
                <Field
                  as={Input}
                  type="text"
                  className="mb-3 border border-black"
                  placeholder={`Enter ${entityLabel} Code`}
                  name={"code"}
                  disabled={isViewMode}
                />
                <ErrorMessage
                  name={"code"}
                  component="div"
                  className="text-red-600"
                />
              </Label>
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1  gap-4 ml-4 mb-6">
              <span className="text-base flex flex-col">Description</span>
              <Label>
                <Field
                  as={Input}
                  type="text"
                  className="mb-3 border border-black"
                  placeholder={`Enter ${entityLabel} Description`}
                  name="description"
                  disabled={isViewMode}
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-600"
                />
              </Label>
            </div>
          </div>
          {isFunction(buttons) && buttons(isSubmitting)}
        </form>
      )}
    </Formik>
  );
};

export default PayGradeBandForm;
