import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isFunction } from "lodash";
import Swal from "sweetalert2";

import { addVendor, addVendorEmpanelment } from "../../../api/vendor";

import { VENDOR_EMPLPANELMENT_STATUS } from "../helper";

import { capturevendorFormData } from "../../../store/vendorFormSlice";
import FormikVendorRegistrationForm from "../forms/vendorRegistrationForm";
import { INITIAL_DATA } from "../forms/vendorRegistrationForm/constants";

const RegisterVendor = ({ next, buttons, formId, className }) => {
  const navigate = useNavigate();
  const { companyId } = useSelector((state) => state.companyData);

  const dispatch = useDispatch();

  const handleSubmit = async (values, options) => {
    try {
      const vendor = await addVendor({
        ...values,
        name: values.vendorName,
        isVendor: true,
        isClient: true,
        isGroupEntity: false,
      });
      const vendorEmp = await addVendorEmpanelment({
        vendorCode: values.vendorCode,
        agreementType: "Manpower Services",
        paymentTerms: "15 days from invoice",
        empanelmentStatus: VENDOR_EMPLPANELMENT_STATUS.ACTIVE,
        clientEntityId: companyId,
        vendorEntityId: vendor.data.id,
      });

      console.log("empan", vendorEmp);
      console.log("ven", vendor);

      dispatch(
        capturevendorFormData({
          vendorId: vendor.data.id,
          vendorEmpanelmentId: vendorEmp.data.id,
          panNum: values?.panNum,
        })
      );

      options.setSubmitting(false);
      options.resetForm(INITIAL_DATA);

      if (isFunction(next))
        next({
          vendorId: vendor.data.id,
          vendorEmpanelmentId: vendorEmp.data.id,
        });
    } catch (err) {
      Swal.fire({
        title: "Error!",
        icon: "error",
        confirmButtonText: "Ok",
      });
      options.setSubmitting(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate("");
  };

  return (
    <FormikVendorRegistrationForm
      handleFormSubmit={handleSubmit}
      handleCancel={handleCancel}
      buttons={buttons}
      className={className}
      formId={formId}
    />
  );
};

export default RegisterVendor;
