import React, { useContext, Suspense, useEffect, lazy } from "react";
import { Route, useLocation, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import Header from "./Header";
import { ThemeContext } from "../context/ThemeContext";
import ErrorBoundary from "../ErrorBoundry";

const Page404 = lazy(() => import("../../pages/404"));

function Layout({ handleLogoutClick, routes, ThemedSuspense }) {
  const { isSidebarOpen, closeSidebar } = useContext(ThemeContext);
  let location = useLocation();

  const { isLoggedIn } = useSelector((state) => state.authentication);

  useEffect(() => {
    closeSidebar();
  }, [location]);

  const renderRoutes = routes.map(
    (route) =>
      route.component && (
        <Route
          key={route.id}
          path={`/${route.path}`}
          element={<route.component />}
        />
      )
  );

  return (
    <div
      className={`flex h-screen bg-gray-50 dark:bg-gray-900 ${
        isSidebarOpen && "overflow-hidden"
      }`}
    >
      <Sidebar />

      <div className="flex flex-col flex-1 w-full">
        <Header handleLogoutClick={handleLogoutClick} />
        <main className="h-full overflow-y-auto container mx-auto px-4">
          {isLoggedIn && (
            <div className="container grid px-6 mx-auto">
              <Suspense fallback={<ThemedSuspense />}>
                <ErrorBoundary>
                  <Routes>
                    {renderRoutes}
                    <Route
                      path="/"
                      element={<Navigate to="/app/dashboard" />}
                    />
                    <Route path="/*" element={<Page404 />} />
                  </Routes>
                </ErrorBoundary>
              </Suspense>
            </div>
          )}
          {!isLoggedIn && <Page404 />}
        </main>
      </div>
    </div>
  );
}

export default Layout;
