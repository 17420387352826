import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logInApi } from '../api/User';
import FormikSignInFrom from '../forms/signIn';
import { logIn } from '../../../store/authenticationSlice';
import { captureCompany } from '../../../store/companySlice';

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    console.log('clicked Login');
    try {
      const responce = await logInApi(values);
      console.log(responce);
      localStorage.setItem('refresh-token', responce.data.refreshToken);

      dispatch(logIn(responce.data.token));
      dispatch(captureCompany(responce.data.userData.company));

      navigate('/app', { replace: true });
    } catch (err) {
      alert(err.response.data.message);
    }
  };

  console.log(process.env.REACT_APP_SERVER);

  return <FormikSignInFrom handleFormSubmit={handleSubmit} />;
}
export default SignIn;
