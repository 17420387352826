import { createSlice } from "@reduxjs/toolkit";

const capturePolicyFormSlice = createSlice({
    name : 'capturePolicyForm',
    initialState: {
        capturePolicyFormData : null
    },

    reducers : {
        setCapturePolicyFormData : (state , action) => {
            state.capturePolicyFormData = action.payload
            console.log(state.capturePolicyFormData)
        }
    }
})

export const {setCapturePolicyFormData} = capturePolicyFormSlice.actions

export default capturePolicyFormSlice.reducer