import baseAPIFactory, { commonThenResult } from '../../../api/mtrack';

export const END_POINTS = {
  GET_DEPARTMENT_LIST: () => '/api/company/department',
  ADD_DEPARTMENT: () => '/api/company/department/add',
  SEARCH_DEPARTMENT: () => '/api/company/department/search',
  EDIT_DEPARTMENT: () => '/api/company/department/edit',
  DELETE_DEPARTMENT: () => '/api/company/department/delete',

  
  ADD_DEPARTMENT_LIST: () => '/api/company/department-bulk/add',
  BULK_DEPARTMENT_FORMAT: () => '/api/company/department-bulk/format',
};

export const listDepartment = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.GET_DEPARTMENT_LIST(), {
    params: {
      entityId: inputData.companyId,
      status: inputData.status,
    },
  });

  return commonThenResult(response);
};

export const searchDepartment = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.SEARCH_DEPARTMENT(), {
    params: {
      entityId: inputData.companyId,
      status: inputData.status,
      // term: inputData.term,
    },
  });

  return commonThenResult(response);
};

export const addDepartment = (inputData) =>
  baseAPIFactory.post(END_POINTS.ADD_DEPARTMENT(), inputData);

export const editDepartment = (inputData) => {
  console.log(inputData);

  return baseAPIFactory.patch(END_POINTS.EDIT_DEPARTMENT(), inputData);
};

export const deleteDepartment = (inputData) => {
  console.log(inputData);

  return baseAPIFactory.patch(END_POINTS.DELETE_DEPARTMENT(), inputData);
};



export const addDepartmentList = async (inputData) => {
  const result_1 = await baseAPIFactory.post(END_POINTS.ADD_DEPARTMENT_LIST(), inputData);
  return commonThenResult(result_1);
};

export const getDepartmentBulkFormat = async (inputData) => {
  const response = await baseAPIFactory.get(END_POINTS.BULK_DEPARTMENT_FORMAT(), {
    params: {
      title: inputData,
    },
  });
  console.log(response);
  return commonThenResult(response)

}