export const LOCATION_INITIAL_DATA = {
  name: "",
  locationCode: "",
  description: "",
  locationHead: [],
  addressLine1: "",
  addressLine2: "",
  countryId: 0,
  stateId: "",
  cityId: "",
  pincode: "",
};
