import React from 'react';
import { ErrorMessage as FormikErrorMessage } from 'formik';
import { HelperText } from '@windmill/react-ui';

const ErrorMessage = (props) => {
  return (
    <div className="mb-3 -mt-3">
      <FormikErrorMessage name={props.name}>
        {(errorMessage) => (
          <HelperText valid={false}>{errorMessage}</HelperText>
        )}
      </FormikErrorMessage>
    </div>
  );
};

export default ErrorMessage;
