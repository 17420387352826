import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Formik } from 'formik';
import { Button, Input, Label } from '@windmill/react-ui';
import { isEmpty, isFunction, snakeCase } from 'lodash';
import PropTypes from 'prop-types';

import useInitialData from '../../../../hooks/useInitialData';
import { getData } from './constants';

import ErrorMessage from '../../../../components/UI Components/ErrorMessage';
import Spinner from '../../../../components/UI Components/Spinner';
import { useSelector } from 'react-redux';
import handleSingleFileUpload from '../../../../components/utilFunction/singleFileUpload';
// import DateSelector from '../../../UI Components/DatePicker';

const DocumentDetails = ({
  initialData,
  handleFormSubmit,
  handleCancel,
  buttons,
  formRequired,
  formId,
  vendorDocumentSchema,
}) => {
  const { t } = useTranslation();

  const [INITIAL_DATA, setINITIALDATA] = useState();

  const { panNum } = useSelector((state) => state.vendorFormData);

  useEffect(() => {
    getData().then((res) => {
      res.panNumber = panNum;
      setINITIALDATA(res);
    });
  }, [panNum]);

  const { isDataEmpty, initialValues } = useInitialData({
    initialData,
    INITIAL_DATA,
  });

  const data = ({ setFieldValue }) =>
    formRequired.map(
      (
        { title, valueKey, isBack, isExpire, isValidCountryId, isMandatory },
        i
      ) => {
        if (isValidCountryId) {
          return (
            <div key={i}>
              <Label>
                <span>
                  {t(snakeCase(`${title}`).toUpperCase())}{' '}
                  {isMandatory ? '*' : ''}
                </span>
                <Field
                  as={Input}
                  className="mt-1 mb-3 border"
                  placeholder={t(snakeCase(`Enter ${title}`).toUpperCase())}
                  name={valueKey}
                />
              </Label>
              <ErrorMessage name={valueKey} />

              <Label>
                <span>{t(snakeCase(`upload ${title}`).toUpperCase())}</span>

                <div className="flex">
                  <Input
                    type="file"
                    name={`${valueKey}Path`}
                    id={`${valueKey}Path`}
                    onChange={async (event) => {
                      const { url } = await handleSingleFileUpload({
                        file: event.currentTarget.files[0],
                      });
                      setFieldValue(`${valueKey}Path`, url);
                    }}
                    className="block mt-1 mb-3 w-10/12 text-sm text-slate-500 file:mr-2 file:py-1 file:px-2 file:rounded-full file:border-0 file:text-xs file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                  />
                  <i> (only pdf, jpeg, jpg files)</i>
                </div>
              </Label>
              <ErrorMessage name={`${valueKey}Path`} />
              {isBack && (
                <>
                  <Label>
                    <span>
                      {t(snakeCase(`upload ${title} back`).toUpperCase())}
                    </span>

                    <Input
                      type="file"
                      id={`${valueKey}BackPath`}
                      name={`${valueKey}BackPath`}
                      onChange={async (event) => {
                        const { url } = await handleSingleFileUpload({
                          file: event.currentTarget.files[0],
                        });

                        setFieldValue(`${valueKey}BackPath`, url);
                      }}
                      className="block mt-1 mb-3 w-full text-sm text-slate-500 file:mr-2 file:py-1 file:px-2 file:rounded-full file:border-0 file:text-xs file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                    />
                  </Label>
                  <ErrorMessage name={`${valueKey}BackPath`} />
                </>
              )}
              {isExpire && (
                <>
                  <Label>
                    <span> {t(`SELECT_EXPIRY_DATE`)}</span>
                    <Field
                      as={Input}
                      type="date"
                      name={`${valueKey}Expiry`}
                      className="border"
                    />
                  </Label>
                  <ErrorMessage name={`${valueKey}Expiry`} />
                </>
              )}
            </div>
          );
        }
        return null;
      }
    );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      enableReinitialize={isDataEmpty}
      validationSchema={vendorDocumentSchema}
    >
      {({ handleSubmit, isSubmitting, setFieldValue }) => (
        <form id={formId} autoComplete="off" onSubmit={handleSubmit}>
          {data({ setFieldValue })}
          {!buttons && (
            <div className="flex items-center justify-center mb-4 mt-8 ">
              <Button
                type="button"
                className="mr-4 -ml-12"
                layout="outline"
                onClick={handleCancel}
              >
                {t('CANCEL')}
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {t('SAVE')}
              </Button>
            </div>
          )}
          {isFunction(buttons) && buttons(handleCancel, isSubmitting)}
        </form>
      )}
    </Formik>
  );
};

DocumentDetails.propTypes = {
  initialData: PropTypes.instanceOf(Object),
  formRequired: PropTypes.array.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default DocumentDetails;
