import { getVendorRequiredDocuments } from '../../../../api/vendor';

export const getData = async () => {
  const result = await getVendorRequiredDocuments(1);
  const names = result.map((i) => {
    return {
      valueKey: i.DocumentType.documentKey,
      isBack: i.DocumentType.documentHasBack,
      isExpire: i.DocumentType.documentHasExpiry,
      isValidCountryId: i.DocumentType.validCountryId,
    };
  });

  const initV = {};

  names.forEach(({ valueKey, isBack, isExpire, isValidCountryId }) => {
    if (isValidCountryId) {
      initV[valueKey] = '';
      initV[`${valueKey}Path`] = '';
      if (isBack) initV[`${valueKey}BackPath`] = '';
      if (isExpire) initV[`${valueKey}Expiry`] = '';
    }
  });

  return initV;
};
