import React, { useState, useEffect } from 'react';
import appLogger from '../../../../config/appLogger';
import { listStates } from '../../../../api/common';
import FormikAddressForm from '../../forms/AddressForm';
import {
  addContact,
  addVendorAddress,
  addVendorEmpanelmentLocation,
} from '../../api/vendor';
import Spinner from '../../../../components/UI Components/Spinner';
import Swal from 'sweetalert2';
import { isFunction } from 'lodash';
import { nameParser } from '../../helper';
import { useNavigate } from 'react-router-dom';

const initialValues = {};

function AddressForm({
  buttons,
  isHQ,
  vendorId,
  formId,
  empanelmentId,
  next,
  reloadStateCallback,
}) {
  // const { vendorId = 1 } = useParams();
  const navigate = useNavigate();

  const [stateList, setStateList] = useState([]);
  const [notLoading, setNotLoading] = useState(true);

  useEffect(() => {
    const fetchState = async () => {
      const list = await listStates(1);
      setStateList(list);
    };
    fetchState();
  }, []);

  const handleSubmit = async (values, options) => {
    setNotLoading(false);

    const { firstName, lastName } = nameParser(values.nameContact);

    console.log('addess', firstName, lastName);

    try {
      const contact = await addContact({
        firstName: firstName,
        lastName: lastName,
        mobile: values.mobileContact,
        email: values.emailContact,
        designation: values.designationContact,
        vendorEmpanelmentId: empanelmentId,
      });
      const vendorAddress = await addVendorAddress({
        ...values,
        name: isHQ ? 'HQ' : 'BRANCH',
        primaryContactId: contact.data.id,
        entityId: vendorId,
        countryId: 1,
        isHQ,
      });

      await addVendorEmpanelmentLocation({
        locationId: vendorAddress.data.id,
        billToAddressId: Math.floor(Math.random() * 10000 + 1),
        locationClientManagerId: contact.data.id,
        locationVendorManagerId: contact.data.id,
        VendorEmpanelmentId: empanelmentId,
      });

      // console.log(vendorAddress);
      options.setSubmitting(false);
      options.resetForm(initialValues);
      if (isFunction(next))
        next({
          vendorId: vendorId,
          vendorEmpanelmentId: empanelmentId,
        });
      if (isFunction(reloadStateCallback)) reloadStateCallback(values);

      setNotLoading(true);
    } catch (err) {
      appLogger.log(err);
      Swal.fire({
        title: 'Error!',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      options.setSubmitting(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate('/app/vendor');
  };

  console.log('EMP ADRS', empanelmentId);

  return (
    <>
      {notLoading ? (
        <FormikAddressForm
          handleFormSubmit={handleSubmit}
          buttons={buttons}
          formId={formId}
          states={stateList}
          handleCancel={handleCancel}
        />
      ) : (
        <Spinner className="absolute top-1/2 right-1/2" />
      )}
    </>
  );
}
export default AddressForm;
