import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
} from "@windmill/react-ui";
import { useTranslation } from "react-i18next";

// Modal Component
const OvertimeApprovalModal = ({
  isOpen,
  onClose,
  taskToApprove,
  status,
  setStatus,
  reasons,
  setReasons,
  overtimeData,
  handleOvertimeChange,
  handleHaltingChange,
  handleApprovalAction,
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    // Validate total hours for each entry
    const newErrors = overtimeData.map((item, index) => {
      const overtimeHours = item.overtimeHours
        ? parseFloat(item.overtimeHours)
        : 0;
      const haltingHours = item.haltingHours
        ? parseFloat(item.haltingHours)
        : 0;
      const totalHours = overtimeHours + haltingHours;

      return totalHours > 4
        ? t(
            `Total overtime and halting hours for date ${new Date(
              item.date
            ).toLocaleDateString()} cannot exceed 4 hours.`
          )
        : "";
    });

    setErrors(newErrors);
  }, [overtimeData, t]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>{t("Approve/Reject Request")}</ModalHeader>
      <ModalBody>
        {taskToApprove && (
          <div className="space-y-4">
            <div className="grid grid-cols-3 gap-4 ">
              <div className="space-y-2">
                <p>
                  <strong>{t("EMPLOYEE_NAME")}:</strong>{" "}
                  {taskToApprove.employeeDetailsMap?.employeeName || "N/A"}
                </p>
                <p>
                  <strong>{t("REQUEST_TYPE")}:</strong>{" "}
                  {taskToApprove.requestOvertime.type || "N/A"}
                </p>
                <p>
                  <strong>{t("NOTE")}:</strong>{" "}
                  {taskToApprove.requestOvertime?.noteByEmployee || "N/A"}
                </p>
              </div>
              <div className="space-y-2">
                <p>
                  <strong>{t("BUSINESS_UNIT")}:</strong>{" "}
                  {taskToApprove.employeeDetailsMap?.businessUnit || "N/A"}
                </p>
                <p>
                  <strong>{t("DEPARTMENT")}:</strong>{" "}
                  {taskToApprove.employeeDetailsMap?.department || "N/A"}
                </p>
                <p>
                  <strong>{t("SUB_DEPARTMENT")}:</strong>{" "}
                  {taskToApprove.employeeDetailsMap?.subDepartment || "N/A"}
                </p>
              </div>
              <div className="space-y-2">
                <p>
                  <strong>{t("EMPLOYEE_CODE")}:</strong>{" "}
                  {taskToApprove.employeeDetailsMap?.employeeCode || "N/A"}
                </p>
                <p>
                  <strong>{t("LEGAL_ENTITY")}:</strong>{" "}
                  {taskToApprove.entityDetail?.name || "N/A"}
                </p>
                <p>
                  <strong>{t("LOCATION")}:</strong>{" "}
                  {taskToApprove.entityLocations?.name || "N/A"}
                </p>
              </div>
            </div>

            <p>
              <strong>{t("OVERTIME_DETAILS")}:</strong>
              <div className="flex flex-col space-y-2 mb-3 ">
                {overtimeData.map((item, index) => (
                  <div className="flex items-center space-x-2 " key={item.date}>
                    <div className="flex items-center">
                      <strong>Date:</strong>
                      <div className="ml-2 ">
                        {new Date(item.date).toLocaleDateString()}
                      </div>
                    </div>

                    <div className="flex items-center">
                      <strong>Overtime:</strong>
                      <Input
                        type="time"
                        className="border w-32 px-0.5 py-1.5 rounded ml-2"
                        value={item.overtimeHours}
                        onChange={(e) =>
                          handleOvertimeChange(index, e.target.value)
                        }
                      />
                    </div>

                    <div className="flex items-center">
                      <strong>Halting:</strong>
                      <Input
                        type="time"
                        className="border w-32 px-0.5 py-1.5 rounded ml-2"
                        value={item.haltingHours}
                        onChange={(e) =>
                          handleHaltingChange(index, e.target.value)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </p>

            {/* Display error messages if any day's total exceeds 4 hours */}
            {errors.map(
              (error, index) =>
                error && (
                  <div key={index} className="text-red-600">
                    {error}
                  </div>
                )
            )}

            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <strong>{t("STATUS")}:</strong>
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className="border-2 border-gray-800 rounded ml-2 h-10"
                >
                  <option value="">{t("Select Status")}</option>
                  <option value="APPROVED">{t("Approved")}</option>
                  <option value="REJECTED">{t("Rejected")}</option>
                </select>
              </div>
              <div className="flex items-center">
                <strong>{t("REASONS")}:</strong>
                <Input
                  className="border-2 border-gray-800 rounded ml-2"
                  value={reasons}
                  onChange={(e) => setReasons(e.target.value)}
                  placeholder={t("Enter reasons")}
                />
              </div>
            </div>
          </div>
        )}
      </ModalBody>
 <ModalFooter>
  <Button layout="outline" onClick={onClose}>
    {t("Cancel")}
  </Button>
  <Button
    onClick={() => {
      const hasError = errors.some((error) => error);
      if (!hasError) {
        // Use the selected status here
        handleApprovalAction(status);
      }
    }}
  >
    {status === "REJECTED" ? t("Reject") : t("Approve")}
  </Button>
</ModalFooter>

    </Modal>
  );
};

export default OvertimeApprovalModal;
