export const INITIAL_DATA = {
  isHQ: '',
  addressLine1: '',
  addressLine2: '',
  stateId: '',
  cityId: '',
  pincode: '',
  telephone: '',
  gstNum: '',
  tinNum: '',
  nameContact: '',
  mobileContact: '',
  designationContact: '',

  legalStatus: '',
  emailContact: '',
};
