import * as Yup from 'yup';

export const logInSchema = Yup.object({
  email: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),

  password: Yup.string().required('Enter Valid Password'),
});

export const registerSchema = Yup.object({
  firstName: Yup.string('Enter your name').required('Name is Required'),
  email: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup.string('Enter your password').required('Password is required'),
  confPassword: Yup.string('confirm password')
    .required('Enter Password For Confirmation')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
