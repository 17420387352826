import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderContain from './HeaderContain';

function Header({ handleLogoutClick }) {
  const navigate = useNavigate();

  function handleSettingClick() {
    navigate('/app/company-master');
  }

  return (
    <HeaderContain
      onClick={handleLogoutClick}
      handleSettingClick={handleSettingClick}
    />
  );
}

export default Header;
