import React from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import * as Icons from '../../../assets/asset';
import * as Img from '../../../assets/asset';
import routes from '../routes/sidebar';
import SidebarSubmenu from './SidebarSubmenu';

function Icon({ icon, ...props }) {
  const Icon = Icons[icon];
  return <Icon {...props} />;
}

function SidebarContent() {
  return (
    <div className="py-4 text-gray-500 dark:text-gray-400">
      <img
        src={Img.ImageLogo}
        alt="logo_mtrack"
        width="170px"
        className="ml-4 mt-2"
      />
      <ul className="mt-6">
        {routes.map((route) =>
          route.routes ? (
            <SidebarSubmenu route={route} key={route.id} />
          ) : (
            <li className="relative px-6 py-3" key={route.id}>
              <NavLink
                to={route.path}
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200"
                activeclassname="text-gray-800 dark:text-gray-100"
              >
                <Routes>
                  <Route
                    path={route.path}
                    element={
                      <span
                        className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                        aria-hidden="true"
                      />
                    }
                  />
                </Routes>
                <Icon
                  className="w-5 h-5"
                  aria-hidden="true"
                  icon={route.icon}
                />
                <span className="ml-4">{route.name}</span>
              </NavLink>
            </li>
          )
        )}
      </ul>
    </div>
  );
}

export default SidebarContent;
