import React from "react";
import { Button, Input, Label, Select } from "@windmill/react-ui";
import { Field, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { isFunction } from "lodash";

import { vendorRegistrationSchema } from "../schema";
import { INITIAL_DATA, legalStatus } from "./constants";
import useInitialData from "../../../../hooks/useInitialData";

import ErrorMessage from "../../../../components/UI Components/ErrorMessage";

const VendorRegistrationForm = ({
  initialData,
  handleFormSubmit,
  handleCancel,
  buttons,
  formId,
}) => {
  const { t } = useTranslation();
  const { isDataEmpty, initialValues } = useInitialData({
    initialData,
    INITIAL_DATA,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      enableReinitialize={isDataEmpty}
      validationSchema={vendorRegistrationSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form id={formId} autoComplete="off" onSubmit={handleSubmit}>
          <Label className="mt-1 mb-3">
            <span>{t("BUSINESS_NAME")} *</span>
            <Field
              as={Input}
              name="businessName"
              className="mt-1 mb-3 border"
              placeholder={t("ENTER_BUSINESS_NAME")}
            />
          </Label>
          <ErrorMessage name="businessName" />
          <Label className="mt-1 mb-3">
            <span>{t("CIN_NUMBER")} </span>
            <Field
              as={Input}
              name="cinNum"
              className="mt-1 mb-3 border"
              placeholder={t("ENTER_CIN_NUMBER")}
            />
          </Label>
          <ErrorMessage name="cinNum" />
          <Label className="mt-1 mb-3">
            <span>{t("NATURE_OF_BUSINESS")} *</span>
            <Field
              as={Input}
              name="natureOfBusiness"
              className="mt-1 mb-3 border"
              placeholder={t("ENTER_NATURE_OF_BUSINESS")}
            />
          </Label>
          <ErrorMessage name="natureOfBusiness" />

          <Label className="mt-1 mb-3">
            <span>{t("EMAIL")} *</span>
            <Field
              as={Input}
              type="email"
              name="email"
              className="mt-1 mb-3 border text-transform: lowercase"
              placeholder={t("ENTER_YOUR_EMAIL")}
            />
          </Label>
          <ErrorMessage name="email" />

          <Label>
            <span> {t("PAN_NUMBER")} *</span>
            <Field
              as={Input}
              className="mt-1 mb-3 border"
              placeholder={t("ENTER_PAN_NUMBER")}
              name="panNum"
            />
          </Label>
          <ErrorMessage name="panNum" />

          <Label>
            <span> {t("LIN_NUMBER")} </span>
            <Field
              as={Input}
              className="mt-1 mb-3 border"
              placeholder={t("ENTER_LIN_NUMBER")}
              name="linNum"
            />
          </Label>
          <ErrorMessage name="linNum" />

          <Label className="mt-1 mb-3">
            <span>{t("LEGAL_STATUS")} *</span>
            <Field as={Select} className="mt-1 mb-3 border" name="legalStatus">
              <option>{t("Select")}</option>
              {legalStatus.map(({ id, name }) => {
                return (
                  <option key={id} value={name}>
                    {name}
                  </option>
                );
              })}
            </Field>
          </Label>
          <ErrorMessage name="legalStatus" />
          {!buttons && (
            <div className="flex items-center justify-center mb-4 mt-8 ">
              <Button
                type="button"
                className="mr-4 -ml-12"
                layout="outline"
                onClick={handleCancel}
              >
                {t("CANCEL")}
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {t("SAVE")}
              </Button>
            </div>
          )}
          {isFunction(buttons) && buttons(handleCancel, isSubmitting)}
        </form>
      )}
    </Formik>
  );
};

export default VendorRegistrationForm;
