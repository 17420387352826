import { createSlice } from '@reduxjs/toolkit';

const initialState = { modalState: false };

const modalManage = createSlice({
  name: 'modalManage',
  initialState,
  reducers: {
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
    },
  },
});

export const { openModal, closeModal } = modalManage.actions;

export default modalManage.reducer;
